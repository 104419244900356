import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'

import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { singleViewSlideshow } from './../units/Units';

import SingleCardItem from './SingleCardItem'
import Container from './Container'
import useFetch from './../units/useFetch';

const SlideCard = ({ loadingCard }) => {
  const isDesktop = useMediaQuery({ query: '(min-device-width: 768px)' }) 

  const [categories, setCategories] = useState([])    
  const [asyncFetch, { response, fetchType }] = useFetch();

  useEffect(() => {
      asyncFetch({ url: "/home-compound-categories", type: "categories", method: "GET" });
    }, []);

  useEffect(() => {
      switch (fetchType) {
          case "categories":
            // console.log('response', response)
            setCategories(response);
            loadingCard(true)
          break;
          default:
              console.log("SlideCard initial call");
      }
  }, [response]);


  if(categories.length < 1) return null
  return (
    <div className="slide-card mt-md-4 pt-md-4">
        <Container>
          { (isDesktop) && <h2>Un repas sain, frais et sur-mesure en 3 étapes!</h2> }
          <div className="slide-card-wrapper">
            {
              (categories.length > 0) &&
                <Slider {...singleViewSlideshow}>
                  { categories.map((category, index) => <div key={index}><SingleCardItem {...category} /></div>) }
                </Slider>
            }
          </div>
        </Container>
    </div>
  );
  
}

export default SlideCard
