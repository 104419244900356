import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import Cookies from 'js-cookie'

import { UserContext } from './../context/UserContext'

import FullPageLoading from './../components/FullPageLoading'

const ProtectedRoute = ({ Component, ...rest }) => {
    const { auth, dataLoading } = useContext(UserContext)

    if(!auth && dataLoading && Cookies.get('166-token')) return <FullPageLoading />
    return (
        auth ? <Route {...rest}>{Component}</Route> : <Redirect to="/login" />
    )
}

export default ProtectedRoute;