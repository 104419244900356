import React from 'react'
import { InstagramLink, FacebookLink, TripadvisorLink } from './../units/Units'

import Instagram from './../assets/img/instagram.svg'
import Facebook from './../assets/img/facebook.svg'
import Tripadvisor from './../assets/img/tripadvisor.svg'
import SimpleTripadvisor from './../assets/img/simple-tripadvisor.svg'
import SimpleInstagram from './../assets/img/simple-instagram.svg'

const SocialMedia = ({ simple }) => {
    return (
        <div className="social-media">
            <p>Suivez-nous</p>
            <a href={ InstagramLink } target="_black"><img src={ (simple) ? SimpleInstagram : Instagram } alt="Instagram" /></a>
            <a href={ FacebookLink } target="_black"><img src={ require('../assets/img/facebook.svg') } alt="Facebook" /></a>
            <a href={ TripadvisorLink } target="_black"><img src={ (simple) ? SimpleTripadvisor : Tripadvisor } alt="Tripadvisor" /></a>
        </div>
    )
}

export default SocialMedia
