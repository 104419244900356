import React from 'react'
import { useMediaQuery } from 'react-responsive'

import poopupCloseIcon from './../assets/img/close-white.svg'

const PoopUp = ({ children, close, large }) => {
    const isDesktop = useMediaQuery({ query: '(min-device-width: 768px)' }) 

    return (
        <div className={`poopup-animated ${large && "large-popup"}`}>
            <div className="poopup-close" onClick={ close }></div>
            
            <img src={ poopupCloseIcon } className="poopup-close-icon" onClick={ close } />
            
            <div className="poopup-content">
                {children}
            </div>
        </div>
    )
}

export default PoopUp
