import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom';

import Cookies from 'js-cookie'
import PageCoverImage from './../assets/img/page-cover.jpg'

import Layout from './../includes/Layout'
import Container from './../components/Container'
import PageCover from './../components/PageCover'
import ForgotPasswordForm from './../components/ForgotPasswordForm'
import FullPageLoading from './../components/FullPageLoading'

import { UserContext } from './../context/UserContext'

const ForgotPassword = () => {
    const { auth, dataLoading, redirect, setRedirect } = useContext(UserContext)

    if(Cookies.get('166-token')){
        if(dataLoading) return <FullPageLoading />
        if(auth) return <Redirect to='/' />
    }

    if(redirect){
        setRedirect(false)
        return <Redirect to="/" />
    }

    return (
        <Layout>
            <PageCover title="Mot de passe oublié" bg={ PageCoverImage } />
        
            <Container>
                <div className="row">
                    <div className="col-md-8 col-lg-6 offset-md-2 offset-lg-3">
                        <div className="wrapper-log">
                            <ForgotPasswordForm />
                        </div>
                    </div>
                </div>
            </Container>
        </Layout>
    
    )
}

export default ForgotPassword