import React, { useState, useContext, useEffect } from 'react'

import Checked from '../assets/img/checked.svg'
import Add from '../assets/img/add.svg'

import { CartContext } from './../context/CartContext'

const SingleAttribute = ({ id, icon, name, price, exist, limited, addOptions, activateQuantity, addDetails, removeDetails }) => {
    const [status, setStatus] = useState(false)
    const [quantity, setQuantity] = useState(0)
    const { cart } = useContext(CartContext)

    const handleClick = () => {
        if(!limited || status){
            addOptions(!status)
            setStatus(!status)

            if(!status){
                setQuantity(1)
                addDetails(id)
            }else{
                setQuantity(0)
                removeDetails(id)
            }
        }
    }
    const addQuantity = () => {
        if(!limited && status){
            setQuantity(quantity + 1)
            addDetails(id)
            addOptions(true)
        }
    }
    const removeQuantity = () => {
        if(quantity > 1){
            setQuantity(quantity - 1)
            removeDetails(id)
            addOptions(false)
        }else{
            handleClick()
        }
    }

    useEffect(() => {
        if(status){
            setStatus(false)
        }
    }, [cart])

    return (
        <div className={ (limited && !status || !exist) ? 'single-attribute blur-it' : 'single-attribute' }>
            <span>{ (status) ? <img src={Checked} alt="Added" /> : <img src={Add} alt="Added" /> }</span>
            {/* (!exist) && (<div className="no-exit">Pas disponible</div>)*/}
            <div className="option-image">
                <img src={icon} alt={name} onClick={ handleClick } />
                {
                    (status && activateQuantity) ?  // && !exist
                        <div className="option-qty">
                            <div className="wrap">
                                <i className="mr-1" onClick={removeQuantity}>-</i>
                                { quantity }
                                <i className="ml-1" onClick={addQuantity}>+</i>
                            </div>
                        </div>
                    :
                        null
                }
            </div>
            <h5>{name}</h5>
            { (price && price > 0) ? <small className="font-italic">+ {price} DH</small> : null }
        </div>
    )
}

export default SingleAttribute
