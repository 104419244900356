import React, { createContext, useState, useEffect } from 'react'
import useFetch from './../units/useFetch'

export const ApplicationContext = createContext()

export const ApplicationContextProvider = ({ children }) => {
    const [ status, seStatus ] = useState(false)
    const [ asyncFetch, {response} ] = useFetch()

    useEffect(() => {
        asyncFetch({ url: '/store-status', type: 'store', method: 'GET'})
    }, [])
    
    useEffect(() => {
        seStatus(response)
    }, [response])

    return (
        <ApplicationContext.Provider value={{status}}>
            {children}
        </ApplicationContext.Provider>
    )
}