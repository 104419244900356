import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import ToggleIcon from './../assets/img/login.svg'
import UserIcon from './../assets/img/user.svg'

import { UserContext } from './../context/UserContext'

const Toggle = () => {
    const { auth } = useContext(UserContext)

    return (
        <div className="toggle">
            {
                (auth) ?  <Link to="/profile"><img src={ UserIcon } alt="toggle-menu" /></Link>
                :  <Link to="/login"><img src={ ToggleIcon } alt="toggle-menu" /></Link>
            }
            
        </div>
    )
}

export default Toggle
