import React, { useState, useContext } from 'react'
import { CartContext } from './../context/CartContext'

const CommentCommande = ({ submitComment }) => {
    const { comment } = useContext(CartContext)
    const [ state, setState ] = useState(comment)

    const handleSubmit = () => {
        submitComment(state)
    }

    return (
        <div className="form-sign">
            <div className="form-group mt-4 mb-0 mb-0">
                <label htmlFor="">Commenter</label>
                <textarea type="text" className="form-control" rows="2" onChange={ (e) => setState(e.target.value) }>{state}</textarea>
            </div>
            <div className="form-group mt-4 mb-0 mb-0">
                <button type="submit" className="btn" onClick={ handleSubmit }>Ajouter</button>
            </div>
        </div>
    )
}

export default CommentCommande
