import React, { useContext } from 'react'
import { CartContext } from './../context/CartContext'

import IconDelivery from './../assets/img/icon-delivery.svg'
import IconPickup from './../assets/img/icon-pickup.svg'

const ApplyOrderType = ({ handleOrderType }) => {
    const { type, timing } = useContext(CartContext)

    const typeOrder = (type) => {
        if(type === 'emporter') return 'À emporter'
        if(type === 'delivery') return 'Livraison'
    }

    return (
        <div className="order-type">
            <div onClick={ handleOrderType }>
                { 
                    (type) ? 
                        <div>
                            <p>
                            <img src={ IconDelivery } alt="Delivery" width="25" /> &nbsp;
                            <img src={ IconPickup } alt="Pickup" width="25" /> &nbsp;
                            Le type de commande : <span>{ typeOrder(type) }</span></p> 
                        </div> 
                    : 
                        <p>
                            <img src={ IconDelivery } alt="Delivery" width="25" /> &nbsp;
                            <img src={ IconPickup } alt="Pickup" width="25" /> &nbsp;
                            Selectioner le type de commande 
                        </p>
                }
            </div>
        </div>
    )
}

export default ApplyOrderType
