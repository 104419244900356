import React, { useContext } from 'react'
import { CartContext } from './../context/CartContext'

import PoopUp from './PoopUp'

const OrderType = ({ submitType }) => {
    const { type } = useContext(CartContext)

    const handleSelection = (value) => {
        if(value === 'emporter' || value === 'delivery'){
            submitType(value)
        }
    }

    return (
        <PoopUp>
            <div className="order-type-popup">
                <h3>Sélectionnez le type de votre commande</h3>
                <div className="d-flex align-items-center justify-content-between">
                    <button className={ (type === 'emporter') ? 'active' : '' } onClick={ () => handleSelection('emporter') }>À emporter</button>
                    <button className={ (type === 'delivery') ? 'active' : '' } onClick={ () => handleSelection('delivery') }>Livraison</button>
                </div>
            </div>
        </PoopUp>
    )
}

export default OrderType
