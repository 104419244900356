import React, { useContext, useState, useEffect } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { UserContext } from './../context/UserContext'

import Loading from './Loading'


const phoneRegex = RegExp(/^((06)|(07))[0-9]{8}$/);
const SignupSchema = Yup.object().shape({
    name: Yup.string().required('Champ obligatoire').min(3, 'Ce champ doit contenir au moins 3 caractères'),
    mobile: Yup.string().matches(phoneRegex, "Téléphone Invalid").required('Champ obligatoire'),
    email: Yup.string().email('Adresse e-mail invalide').required('Champ obligatoire'),
    password: Yup.string().required('Champ obligatoire').min(8, 'Ce champ doit contenir au moins 8 caractères'),
})


const RegisterForm = () => {
    const { SignUp, loadingForm, setLoadingForm } = useContext(UserContext)

    return (
        <Formik
            initialValues={{ name: '', mobile: '', email: '', password: '' }}
            validationSchema={SignupSchema}
            onSubmit={(values, { setSubmitting }) => {
                setLoadingForm(true)
                // let timer = setTimeout(() => {
                setTimeout(() => {
                    setSubmitting(false);
                    SignUp(values)
                }, 400);
                // clearTimeout(timer)
            }}
        >
            {({
                values,
                handleChange,
                handleBlur,
                isSubmitting,
            }) => (
                <Form className="form-sign">
                    <div className="form-group">
                        <label htmlFor="name">Nom</label>
                        <input
                            type="text"
                            className="form-control"
                            name="name"
                            id="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                        />
                        <ErrorMessage name="name" component="div" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            name="email"
                            id="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                        />
                        <ErrorMessage name="email" component="div" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="mobile">Téléphone</label>
                        <input
                            type="mobile"
                            className="form-control"
                            name="mobile"
                            id="mobile"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.mobile}
                        />
                        <ErrorMessage name="mobile" component="div" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Mot de passe</label>
                        <input
                            type="password"
                            className="form-control"
                            name="password"
                            id="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                        />
                        <ErrorMessage name="password" component="div" />
                    </div>
                    <div className="form-group mt-4 mb-0 mb-0">
                        <button type="submit" className="btn" disabled={isSubmitting}>S'inscrire</button>
                    </div>

                    { (loadingForm) && <div className="mt-3 text-center"><Loading other="mt-4" /></div>  }

                </Form>
            )}
        </Formik>
    )
}

export default RegisterForm
