import React, {useContext, useState, useEffect} from 'react'
import { CartContext } from './../context/CartContext'
import TimeKeeper from 'react-timekeeper'

const PickTime = ({ submitTiming }) => {
    const { timing } = useContext(CartContext)
    const [ state, setState ] = useState('22:05')

    const handleSubmit = () => {
        submitTiming(state)
    }

    function addZero(i) {
        if (i < 10) {
          i = "0" + i;
        }
        return i;
    }

    useEffect(() => {
        if(!timing){
            let d = new Date()
            let date = addZero(d.getHours()) + ':' + addZero(d.getMinutes())
            setState(date)
        }else{
            setState(timing)
        }
    }, [])

    return (
        <div className="form-sign text-center">
            <p><strong>Pour toute commande, merci de nous préciser l'heure de votre passage</strong></p>
            <TimeKeeper 
                time={state}
                onChange={(newTime) => setState(newTime.formatted24)} 
                switchToMinuteOnHourSelect 
                hour24Mode 
            />
            <div className="form-group mt-4 mb-0 mb-0"><button type="submit" className="btn" onClick={ handleSubmit }>Ajouter</button></div>
        </div>
    )
}

export default PickTime
