import React, { useState, useEffect } from 'react'

import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { carouselViewSlideshow } from './../units/Units'
import useFetch from './../units/useFetch'

const Gallery = () => {
    const [gallery, setGallery] = useState([])
    const [asyncFetch, {response, fetchType}] = useFetch()

    useEffect(() => {
        asyncFetch({ url: '/gallery', type: 'gallery', method: 'GET'  })
    }, [])

    useEffect(() => {
        switch(fetchType){
            case 'gallery':
                setGallery(response)
            break;
            default:
                console.log("Gallery initial call");
        }
    }, [response])



    if(gallery.length < 1) return null
    return (
        (gallery.length > 0) &&
            <div className="gallery">
                <Slider {...carouselViewSlideshow}>
                    {gallery.map((image, index) => 
                        <div key={index}>
                            <div className="single-gallery">
                                <img src={image.image} alt={'image-' + index} />
                            </div>
                        </div>
                    )}
                </Slider>
            </div>
    )
}

export default Gallery
