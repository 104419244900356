import React from 'react'

const CardInfo = ({ children, otherClass }) => {
    return (
        <div className={"card " + otherClass}>
            {children}
        </div>
    )
}

export default CardInfo
