import React, { useEffect, useState } from 'react'
import {GoogleMapToken} from './../units/Units'

let map
let infoWindow 
let geocoder
let marker
let previousMarker
let myLatLng = {lat: 30.4229095, lng: -9.6002691}

const GoogleMap = ({submitMap}) => {
  const [select, setSelect] = useState(false)
  const [latLat, setLatLat] = useState(null)
  const [latLng, setLatLng] = useState(null)
  // const [address, setAddress] = useState({street: 'Street ...', area: 'Area ...', block: 'Block ...',  code_postal: 'Code postal ...'})

  useEffect(() => {
    if (!window.google) {
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = `https://maps.google.com/maps/api/js?key=${GoogleMapToken}`;
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
      // Below is important. 
      //We cannot access google.maps until it's finished loading
      s.addEventListener('load', e => {
        initMap()
      })
    } else {
      initMap()
    }
  }, [])

  const initMap = () => {
    map = new window.google.maps.Map(document.getElementById('map'), {
      zoom: 13,
      center: myLatLng,
      mapTypeId: 'terrain'
    })

    marker = new window.google.maps.Marker({
      position: myLatLng,
      map: map,
    })

    let triangleCoords = [
        {lat: 30.427266420415307, lng: -9.618279233970497},
        {lat: 30.42862299506507, lng: -9.607371259002235},
        {lat: 30.431833479909002, lng: -9.598770740279036},
        {lat: 30.444177035826982, lng: -9.586918805947363},
        {lat: 30.444099142742346, lng: -9.586954715696919},
        {lat: 30.438575853071157, lng: -9.56571348488086},
        {lat: 30.43441083492563, lng: -9.557236527913147},
        {lat: 30.422292151965493, lng: -9.554089996672843},
        {lat: 30.40429222569955, lng: -9.56546995132635},
        {lat: 30.38648325105953, lng: -9.588781400742846},
        {lat: 30.39308514229367, lng: -9.59589776175315},
        {lat: 30.418156680729737, lng: -9.604155425944384},
        {lat: 30.427266420415307, lng: -9.618279233970497}
    ];

    let bermudaTriangle = new window.google.maps.Polygon({
        paths: triangleCoords,
        strokeColor: '#5AA804',
        strokeOpacity: 0.1,
        strokeWeight: 3,
        fillColor: '#5AA804',
        fillOpacity: 0.1
    });
    bermudaTriangle.setMap(map);

    bermudaTriangle.addListener('click', showArrays);

    infoWindow = new window.google.maps.InfoWindow;
    geocoder = new window.google.maps.Geocoder
  }

  // const geocodeLatLng = (currentlat, currentlng) => {
  //     var latlng = {lat: currentlat, currentlng, lng: currentlat, currentlng};
  //     geocoder.geocode({'location': latlng}, function(results, status) {
  //         if (status === 'OK') {
  //             if (results[0]) {
  //                 var marker = new window.google.maps.Marker({
  //                     position: latlng,
  //                     map: map
  //                 });
  //                 infoWindow.setContent(results[0].formatted_address);
  //                 infoWindow.open(map, marker);
  //             } else {
  //                 window.alert('No results found');
  //             }
  //         } else {
  //             window.alert('Geocoder failed due to: ' + status);
  //         }
  //     });
  // }

  const showArrays = (event) => {
      var fullAddress = null
      var currentlat = event.latLng.lat()
      var currentlng = event.latLng.lng()

      setLatLat(event.latLng.lat())
      setLatLng(event.latLng.lng())
      setSelect(true)

      var newLatLng = {lat: currentlat, lng: currentlng}

      // geocodeLatLng(currentlat, currentlng);

      if (previousMarker) previousMarker.setMap(null);

      previousMarker = new window.google.maps.Marker({
          position: newLatLng,
          map: map,
      });

      // var contentString = 'Clicked location: <br>' + currentlat + ',' + currentlng + '<br>';
      // contentString += 'Address : ' + fullAddress + '<br>';

      // infoWindow.setContent(contentString);
      // infoWindow.setPosition(event.latLng);

      // infoWindow.open(map);
  }

  const handleMapData = () => {
    if(select){
      fetch('https://open.mapquestapi.com/geocoding/v1/reverse?key=rVlaBqXGpr5QbuchCNTeAywb3AxPJcfo&location='+latLat+','+latLng)
      .then(res => res.json())
      .then((result) => {
        submitMap(latLat, latLng, result.results[0].locations[0])
      },(error) => {
        alert(error)
      })
    }else{
      alert('Sélectionnez votre emplacement')
    }
  }
  
  return (
    <>
      <div style={{ width: '100%', height: 400 }} id="map" />
      <div className="form-group form-sign mt-4 mb-0">
          <button type="submit" className="btn" disabled={(!select) ? true : false} onClick={ handleMapData }>Ajouter</button>
      </div>
    </>
  );
}
 
export default GoogleMap
