import React, { useContext, createContext, useState, useEffect } from 'react'
import useFetch from './../units/useFetch'

import {Url} from './../units/Units'

import { UserContext } from './../context/UserContext'
import { ApplicationContext } from './../context/ApplicationContext'
import Cookies from 'js-cookie'

export const CartContext = createContext()
const initialCart = [{articles: [], subtotal: 0, total: 0, shipment: 0 }]

export const CartContextProvider = ({ children }) => {
    const { auth } = useContext(UserContext)
    const { status } = useContext(ApplicationContext)

    const [ flashErrorCart, setFlashErrorCart ] = useState(null)
    const [ flashSuccessCart, setFlashSuccessCart ] = useState(null)

    const [ loadingForm, setLoadingForm ] = useState(true)
    const [ cartLocalStorage, setCartLocalStorage ] = useState([])
    const [ redirect, setRedirect ] = useState(false)
    const [ cart, setCart ] = useState(initialCart)
    const [ coupon, setCoupoon ] = useState(null)
    const [ type, setType ] = useState(null)
    const [ address, setAddress ] = useState(null)
    const [ attitude, setAttitude ] = useState(null)
    const [ subAddress, setSubAddress ] = useState(null)
    const [ comment, setComment ] = useState('')
    const [ timing, setTiming ] = useState(null)
    const [ payment, setPayment ] = useState(null)

    const [ asyncFetch, {extraData, response, fetchType, dataLoading, flashError , flashSuccess} ] = useFetch()
    
    useEffect(() => {
        asyncFetch({ url: '/get-cart', type: 'getCart', method: 'POST', body: JSON.stringify({cart: JSON.parse(localStorage.getItem('166-cart')), type: type}) })
    }, [type])

    useEffect(() => {
        try{
            let local = localStorage.getItem('166-cart')
            if(!local || !Array.isArray(JSON.parse(local))){
                localStorage.setItem('166-cart', JSON.stringify([]))
            }
            let cartStorage = JSON.parse(localStorage.getItem('166-cart'))
    
            if(cartStorage && cartStorage.length > 0){
                setCartLocalStorage(cartStorage)
                
                let coupon = localStorage.getItem('coupon')
                if(coupon) setCoupoon(localStorage.getItem('coupon'))
            }else{
                setCoupoon(null)
                localStorage.removeItem('coupon')
            }
        }catch(e){
            localStorage.setItem('166-cart', JSON.stringify([]))
        }
    }, [])

    useEffect(() => {
        localStorage.setItem('166-cart', JSON.stringify(cartLocalStorage))
        if(cartLocalStorage.length > 0){
            asyncFetch({ url: '/get-cart', type: 'getCart', method: 'POST', body: JSON.stringify({cart: JSON.parse(localStorage.getItem('166-cart')), coupon: localStorage.getItem('coupon'), type: type}) })
        }else{
            setCart(initialCart)
        }
    }, [cartLocalStorage])

    useEffect(() => {
        localStorage.setItem('166-cart', JSON.stringify(extraData))
        asyncFetch({ url: '/get-cart', type: 'getCart', method: 'POST', body: JSON.stringify({cart: extraData, coupon: localStorage.getItem('coupon'), type: type}) })
    }, [extraData])

    const clearAll = () => {
        localStorage.removeItem('coupon')
        localStorage.setItem('166-cart', JSON.stringify([]))
        if(cartLocalStorage.length > 0) setCartLocalStorage([])
        if(payment) setPayment(null)
        if(coupon) setCoupoon(null)
        if(type) setType(null)
        if(comment) setComment('')
        if(comment) setComment('')
        if(address) setAddress(null)
        if(timing) setTiming(null)
        if(comment) setComment('')
        if(subAddress) setSubAddress(null)
        if(attitude) setAttitude(null)
    }
    
    useEffect(() => {
        switch(fetchType){
            case 'getCart':
                let local = JSON.parse(localStorage.getItem('166-cart'))
                if( local && local.length > 0){
                    if(response && response.articles && response.articles.length > 0){
                        setCart(response)
                        if(cartLocalStorage && cartLocalStorage.length < 1){
                            setCoupoon(null)
                            localStorage.removeItem('coupon')
                        }else{
                            setCoupoon(localStorage.getItem('coupon'))
                        }
                    }
                }else{
                    setCart(initialCart)
                    setCartLocalStorage([])
                }
                setLoadingForm(false)
                break;
            case 'checkCoupon':
                    setCoupoon(response.code)
                    localStorage.setItem('coupon', response.code)
                    asyncFetch({ url: '/get-cart', type: 'getCart', method: 'POST', body: JSON.stringify({cart: JSON.parse(localStorage.getItem('166-cart')), coupon: response.code, type: type}) })
                break;
            case 'Order':
                // console.log(response)
                if(response){
                    clearAll()
                    if(response.mode === 'card'){
                        window.location.href = Url + '/complete-payment/' + response.id;
                    }else{
                        setTimeout(() => {
                            setRedirect(true)
                            setLoadingForm(false)
                        }, 500);
                    }
                    // clearTimeout(timer)
                }

                break;
            default: 
                console.log('Cart initial data')
        }
    }, [response])

    // Handle Errors
    useEffect(() => {
        if(fetchType === 'checkCoupon') setLoadingForm(false)
        if(fetchType === 'Order') setLoadingForm(false)
        setFlashErrorCart(flashError)
    }, [flashError])
    useEffect(() => {
        setFlashSuccessCart(flashSuccess)
    }, [flashSuccess])


    const handleCouponAdded = (code) => {
        asyncFetch({ url: '/check-coupon', type: 'checkCoupon', method: 'POST', body: JSON.stringify({code: code}) })
    }
    const handleCouponRemoved = () => {
        if(localStorage.getItem('coupon')){
            setCoupoon('')
            localStorage.removeItem('coupon')
            asyncFetch({ url: '/get-cart', type: 'getCart', method: 'POST', body: JSON.stringify({cart: JSON.parse(localStorage.getItem('166-cart')), type: type}) })
        }
    }

    const handleAddToCart = (product_id, type = 'simple', details = []) => {
        if(type === 'simple'){
            let exists = false
            let newLocal = cartLocalStorage.map((row, key) => {
                if(row.product === product_id){
                    cartLocalStorage[key].qty += 1
                    exists = true
                }
                return row
            });
            if(exists) setCartLocalStorage(newLocal)
            else setCartLocalStorage([ ...cartLocalStorage, {'product': product_id, qty: 1, details: []}])
        }

        if(type === 'compound'){
            let exists = false
            let newLocal = cartLocalStorage.map((row, key) => {
                if(row.product === product_id && JSON.stringify(row) === JSON.stringify(details) ){
                    cartLocalStorage[key].qty += 1
                    exists = true
                }
                return row
            });
            if(exists){
                setCartLocalStorage(newLocal)
            } else{
                const newLocalStorage = [ ...cartLocalStorage, {'product': product_id, qty: 1, details: details}]
                setCartLocalStorage( newLocalStorage )
            }
        }
            
    }
    
    const handleUpdateCart = (key, product_id, type) => {
        let newLocal = cartLocalStorage.filter((row, index) => {
            if(row.product === product_id && index === key){
                if(type === 'increase') cartLocalStorage[index].qty += 1
                if(type === 'decrease') cartLocalStorage[index].qty -= 1
            }
            if(row.qty > 0) return row
        });

        setCartLocalStorage(newLocal)
        // else setCartLocalStorage([ ...cartLocalStorage, {'product': product_id, qty: 1, details: []}])
    }

    const handleCommande = () => {
        if(status){
            if(auth && Cookies.get('166-token')){
                if(type === 'emporter' || type === 'delivery'){
                    if(payment === 'card' || payment === 'surplace'){
                        if(type === 'delivery'){
                            if(address && address.area && address.block && address.street){
                                asyncFetch({ url: '/add-order', type: 'Order', method: 'POST', body: JSON.stringify({cart: JSON.parse(localStorage.getItem('166-cart')), coupon: localStorage.getItem('coupon'), orderType: type, comment: comment, address: address, attitude: attitude, payment: payment }) })
                            }else{
                                setLoadingForm(false)
                                setFlashErrorCart({ error: 'Vous devez ajouter votre adresse de la livraison' })
                            }
                        }else if(type === 'emporter'){
                            if(timing){
                                asyncFetch({ url: '/add-order', type: 'Order', method: 'POST', body: JSON.stringify({cart: JSON.parse(localStorage.getItem('166-cart')), coupon: localStorage.getItem('coupon'), orderType: type, comment: comment, timing: timing, payment: payment }) })
                            }else{
                                setLoadingForm(false)
                                setFlashErrorCart({ error: 'Vous devez sélectionner l\'heure de la commande' })
                            }
                        }else{
                            setLoadingForm(false)
                            setFlashErrorCart({ error: 'Il y a un problème, veuillez réessayer plus tard' })
                        }
                    }else{
                        setLoadingForm(false)
                        setFlashErrorCart({ error: 'Vous devez choisir le mode de paiement' })
                    }

                }else{
                    setFlashErrorCart({ error: 'Vous devez choisir le type de votre commande' })
                    setLoadingForm(false)
                    setType(null)
                }
            }else{
                setFlashErrorCart({ error: 'Merci de se connectez avant de commander' })
                setLoadingForm(false)
            }
        }else{
            setFlashErrorCart({ error: 'Le restaurant est fermé on pourrait pas recevoir votre commande en ce moment' })
            setLoadingForm(false)
        }
    }

    return (
        <CartContext.Provider value={{
                        flashErrorCart, setFlashErrorCart, flashSuccessCart, setFlashSuccessCart, 
                        type, setType,
                        comment, setComment,
                        timing, setTiming,
                        payment, setPayment,
                        address, setAddress, setAttitude, subAddress, setSubAddress, 
                        cart, dataLoading, loadingForm, setLoadingForm, coupon, handleCouponAdded, handleCouponRemoved, handleAddToCart, redirect, setRedirect, handleUpdateCart, handleCommande}}>
            {children}
        </CartContext.Provider>
    )
}