import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import Container from './../components/Container'
import Logo from './../components/Logo'
import Menu from './../components/Menu'
import Button from './../components/Button'
import Toggle from './../components/Toggle'

import ToggleMenu from './../components/Mobile/ToggleMenu'
import MenuMobile from './../components/Mobile/MenuMobile'

const Header = () => {
    const isDesktop = useMediaQuery({ query: '(min-device-width: 768px)' })
    const [ menu, setMenu ] = useState(false)

    const openMenu = () => {
        setMenu(true)
    }
    const closeMenu = () => {
        setMenu(false)
    }

    return (
        <header>
            <Container>
                {
                    (isDesktop) ?
                        <div className="row m-0">
                            <Logo />
                            <div className="wrap">
                                <div className="wrap-container">
                                    <Menu />
                                    <div className="d-flex">
                                        <Button link="/commander" title="Commander" />
                                        <Toggle />
                                    </div>
                                </div>
                            </div>
                        </div>
                    :
                        <div className="d-flex justify-content-end">
                            <ToggleMenu openMenu={ openMenu } />
                            <MenuMobile menu={ menu } closeMenu={ closeMenu } />
                        </div>
                }
            </Container>
        </header>
    )
}

export default Header