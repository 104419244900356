import React, { useState, useEffect } from 'react'
import useFetch from './../units/useFetch'
import { useMediaQuery } from 'react-responsive'

import SecondLayout from './../includes/SecondLayout'
import PageCover from './../components/PageCover'

import Container from './../components/Container'
import CardInfo from './../components/CardInfo'
import GoogleMap from './../components/GoogleMap'
import FullPageLoading from './../components/FullPageLoading'

const Contact = () => {
    const isDesktop = useMediaQuery({ query: '(min-device-width: 768px)' }) 
    const [ fields, setFields ] = useState()
    const [ asyncFetch, { response } ] = useFetch()
    useEffect(() => {
        asyncFetch({url: "/contact", type: "contact", method: "GET"})
    }, [])

    useEffect(() => {
        setFields(response)
    }, [response])

    return (
        (fields) ? 
            <SecondLayout v2 url='/'>
                <PageCover title={ fields.title } bg={ fields['image'] } />
                
                <Container>
                    <div className="contact-page my-md-5 py-5">
                        <div className="row position-relative">
                            <CardInfo otherClass="col-md-5">
                                <h3>Contact info:</h3>
                                <div className="contact-address">
                                    <p>{ fields['address'] }</p>
                                </div>
                                <div className="contact-email">
                                    <h4>Email:</h4>
                                    <p><a href={'mailto:' + fields['email']}>{ fields['email'] }</a></p>
                                </div>
                                <div className="contact-tel">
                                    <h4>Tél:</h4>
                                    <p><a href={'tel:' + fields['tel']}>{ fields['tel'] }</a></p>
                                </div>
                            </CardInfo>
                            <div className="offset-md-4 col-md-8 map-card">
                                <div className="wrap">
                                    { (isDesktop) && <h2>Contactez-nouz</h2> }
                                    <div className="map"><GoogleMap lat={fields['latitude']} lng={fields['longitude']}  /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </SecondLayout>
        : 
            <FullPageLoading />
    )
}

export default Contact