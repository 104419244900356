import React from 'react'
import LoadingCircle from './LoadingCircle'

const Loading = ({ other }) => {
    return (
        // <div className={ "LoaderBalls " + other}>
        //     <div className="LoaderBalls__item"></div>
        //     <div className="LoaderBalls__item"></div>
        //     <div className="LoaderBalls__item"></div>
        // </div>
        <LoadingCircle />
    )
}

export default Loading
