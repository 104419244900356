import React, { useContext } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { UserContext } from './../../context/UserContext'
import ProfileLayout from './../../includes/ProfileLayout'
import Loading from './../../components/Loading'

const SignupSchema = Yup.object().shape({
    name: Yup.string().required('Champ obligatoire').min(3, 'Ce champ doit contenir au moins 3 caractères'),
    telephone: Yup.string().matches(/^[0-9]{10}$/, 'Le numéro de téléphone doit être composé de 10 chiffres').required('Champ obligatoire'),
})

const Profile = () => {
    const { user, UpdateProfile, loadingForm, setLoadingForm } = useContext(UserContext)

    if(!user) return null
    return (
        <ProfileLayout>
            <Formik
                initialValues={{ name: (user && user.name) ? user.name : '', email: (user && user.email) ? user.email : '', telephone: (user && user.mobile) ? user.mobile : '' }}
                validationSchema={SignupSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setLoadingForm(true)
                    setTimeout(() => {
                        setSubmitting(false);
                        UpdateProfile(values)
                    }, 400);
                }}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    isSubmitting,
                }) => (
                    <Form className="form-sign">
                        <div className="form-group">
                            <label htmlFor="name">Nom</label>
                            <input
                                type="name"
                                className="form-control"
                                name="name"
                                id="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                            />
                            <ErrorMessage name="name" component="div" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                disabled
                                value={values.email}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="telephone">Telephone</label>
                            <input
                                type="telephone"
                                className="form-control"
                                name="telephone"
                                id="telephone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.telephone}
                            />
                            <ErrorMessage name="telephone" component="div" />
                        </div>
                        <div className="form-group mt-4 mb-0 mb-0">
                            <button type="submit" className="btn" disabled={isSubmitting}>Enregistrer</button>
                        </div>
                        
                        { (loadingForm) && <div className="mt-3 text-center"><Loading other="mt-4" /></div>  }
                    </Form>
                )}
            </Formik>
        </ProfileLayout>
    )
}

export default Profile
