import React from 'react'
import LogoImage from './../assets/img/logo.svg'
import { Link } from 'react-router-dom'

const Logo = () => {
    return (
        <div className="logo">
            <Link to="/">
                <img src={ LogoImage } alt="logo" />
            </Link>
        </div>
    )
}

export default Logo
