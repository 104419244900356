import React from 'react'

import Container from './../Container'
import Menu from './../Menu'
import Button from './../Button'
import SocialMedia from './../SocialMedia'

import CloseMenuIcon from './../../assets/img/menuClose.svg'

const MenuMobile = ({ menu, closeMenu }) => {
    return (
        <nav className={ (menu) ? 'mobile-menu active' : 'mobile-menu' }>
            <img src={ CloseMenuIcon } className="close-menu-mobile" onClick={ closeMenu } alt="Close Menu" />
            <Container>
                <div className="menu-down">
                    <Button link="/commander" title="Commander en ligne" />
                    <Menu />
                </div>
                <div className="menu-down">
                    <SocialMedia simple={true} />
                    <p>&copy; 2020 116 - By Intuitive design</p>
                </div>
            </Container>
        </nav>
    )
}

export default MenuMobile
