import React, { useState } from 'react'

import Layout from './../includes/Layout'
import Slideshow from './../components/Slideshow'
import Gallery from './../components/Gallery'
import Stores from './../components/Stores'
import SlideCard from './../components/SlideCard'
import FullWidthBackground from './../components/FullWidthBackground'
import FullPageLoading from './../components/FullPageLoading'

const Home = () => {
    const [slide, setSlide] = useState(false)
    const [card, setCard] = useState(false)

    const loadingSlide = (para) => setSlide(para)
    const loadingCard = (para) => setCard(para)


    return (
        <Layout>
            <Slideshow loadingSlide={loadingSlide} />
            
            <FullWidthBackground>
                <SlideCard loadingCard={loadingCard} />
                <Stores />
            </FullWidthBackground>

            <Gallery />

            { (!slide || !card) && (<FullPageLoading />) }
        </Layout>
    )
}

export default Home