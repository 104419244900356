import React from 'react'

import Header from './Header'
import Footer from './Footer'
import ContactDailer from './../components/ContactDailer'
import FlashMessage from './../components/FlashMessage'

import ErrorBoundary from './ErrorBoundary'
import FullPageLoading from './../components/FullPageLoading'

const Layout = ({ children }) => {

    return (
        <>
            <Header />
            <FlashMessage />

            <ErrorBoundary>
                { children }
            </ErrorBoundary>

            {/*<ContactDailer />*/}
            <Footer />
        </>
    )
}

export default Layout