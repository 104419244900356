import React, { useEffect, useContext } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'

import { UserContext } from './../context/UserContext'
import Loading from './Loading'

const SignupSchema = Yup.object().shape({
    email: Yup.string().email('Adresse e-mail invalide').required('Champ obligatoire'),
    password: Yup.string().required('Champ obligatoire').min(8, 'Ce champ doit contenir au moins 8 caractères'),
})

const LoginForm = () => {
    const { SignIn, loadingForm, setLoadingForm } = useContext(UserContext)

    return (
        <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={SignupSchema}
            onSubmit={(values, { setSubmitting }) => {
                setLoadingForm(true)
                // let timer = setTimeout(() => {
                setTimeout(() => {
                    setSubmitting(false);
                    SignIn(values)
                }, 400);
                // clearTimeout(timer)
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <>
                    <Form className="form-sign">
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                className="form-control"
                                name="email"
                                id="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                            />
                            <ErrorMessage name="email" component="div" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Mot de passe</label>
                            <input
                                type="password"
                                className="form-control"
                                name="password"
                                id="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                            />
                            <ErrorMessage name="password" component="div" />
                        </div>
                        <div className="form-group mt-3 mb-0 mb-0">
                            <Link to="/forgot-password">Mot de passe oublié ?</Link>
                        </div>
                        <div className="form-group mt-4 mb-0 mb-0">
                            <button type="submit" className="btn" disabled={isSubmitting}>S'identifier</button>
                        </div>

                        { (loadingForm) && <div className="mt-3 text-center"><Loading other="mt-4" /></div>  }
                    </Form>
                </>
            )}
      </Formik>
    )
}

export default LoginForm