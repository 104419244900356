import React, { useState, useContext, useEffect, Component } from 'react'
import Close from './../assets/img/close.svg'
import CouponDelivery from './../assets/img/coupon-delivery.svg'

import { CartContext } from './../context/CartContext'

const ApplyCoupon = () => {
    const { cart, coupon, flashErrorCart, handleCouponAdded, setLoadingForm, handleCouponRemoved } = useContext(CartContext)
    const [ disable, setDisable ] = useState('')
    const [ code, setCode ] = useState('')
    const [ formError, setFormError ] = useState(false)
    const [ status, setStatus ] = useState(false)

    useEffect(() => {
        if(flashErrorCart && flashErrorCart.coupon) setFormError(flashErrorCart.coupon)
        if(disable) setDisable(false) 
    }, [flashErrorCart])

    useEffect(() => {
        if(!coupon) setCode('')
        else setCode(coupon)

        if(disable) setDisable(false) 
    }, [coupon])

    const handleSubmit = () => {
        if(code){
            setFormError(false)
            setLoadingForm(true)
            handleCouponAdded(code)
            setDisable(true)
        }else{
            setFormError(true)
        } 
    }

    const removeCoupon = () => {
        setLoadingForm(true)
        setCode('')
        handleCouponRemoved()
    }

    const closeForm = () => {
        setStatus(false)
        setCode('')
    }

    const handleBlur = () => {
        setFormError(false)
        setCode('')
    }

    return (
        <div className="cart-coupon">
            {
                (!coupon) ?    
                    (status) ?              
                        <div className="form-coupon">
                            <input type="text" value={code} onChange={ (e) => setCode(e.target.value) } className={ (formError) ? 'input-error' : '' } onFocus={ handleBlur } />
                            <button type="submit" disabled={ disable } onClick={ handleSubmit }>Appliquer</button>
                            <div className="close-coupon" onClick={ closeForm }><img src={Close} alt="" /></div>
                        </div>
                    : 
                        <div onClick={ () => setStatus(true) }><img src={ CouponDelivery } alt="" /> Coupon de réduction</div>
                : 
                    <div onClick={ () => removeCoupon }>Coupon : { coupon } { (cart && cart.coupon_details) && <i>( -{cart.coupon_details.price} DH)</i> } - <button className="btn btn-danger btn-sm" onClick={ removeCoupon }>Retirer</button></div>

            }
        </div>
    )
}

export default ApplyCoupon
