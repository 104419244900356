import React, { useState, useEffect } from 'react'
import useFetch from './../units/useFetch'
import { useMediaQuery } from 'react-responsive'

import SecondLayout from './../includes/SecondLayout'

import Loading from './../components/Loading'
import CartData from './../components/CartData'
import Container from './../components/Container'
import HorizontalArticle from './../components/HorizontalArticle'

const Order = () => {
    const isDesktop = useMediaQuery({ query: '(min-device-width: 768px)' }) 
    const [loading, setLoading] = useState(false)
    const [articles, setArticles] = useState([])
    const [categories, setCategories] = useState([])
    const [current, setCurrent] = useState(null)

    const [fetchCategoryType, setFetchCategoryType] = useState(null)

    const [subCategories, setSubCategories] = useState([])
    const [currentSubCategory, setCurrentSubCategory] = useState(null)

    const [compound, setCompound] = useState(false)
    const [compoundCategory, setCompoundCategory] = useState([])
    const [currentCompoundCategory, setCurrentCompoundCategory] = useState(null)

    const defaultSelected = 'compount' // simple

    // Simple
    const [ asyncFetch, { response, fetchType } ] = useFetch()
    useEffect(() => {
        setLoading(true)
        asyncFetch({url: "/simple-categories", type: "simpleCategory", method: "GET"})
    }, [])

    useEffect(() => {
        switch(fetchType){
            case 'simpleCategory':
                setCategories(response)
                if(defaultSelected === 'simple'){
                    if(response[0]){
                        if(response[0].count_childs === 0) fetchCategories(response[0].id, 'articles')
                        else fetchCategories(response[0].id, 'categories')
                    }
                    if(compound) setCompound(false)
                }else{
                    setCompound(true)
                }
                setLoading(false)
                break;
            case 'subCategories':
                setSubCategories(response)
                if(response[0]) setCurrentSubCategory(response[0].id)
                if(compound) setCompound(false)
                setLoading(false)
                break;
            case 'compoundCategory':
                setCompoundCategory(response)
                if(response[0]) setCurrentCompoundCategory(response[0].id)
                setLoading(false)
                break;
            case 'articles':
                setArticles(response)
                setLoading(false)
                break;
            default: 
                console.log("Categories initial data")
        }

    }, [response])

    useEffect(() => {
        if(current !== null){
            setLoading(true)
            // console.log('Loading current true')
            if(fetchCategoryType === 'categories'){
                asyncFetch({ url: "/fetch-subcategories/" + current, type: "subCategories", method: "GET" })
            }else{
                asyncFetch({ url: "/category-articles/" + current, type: "articles", method: "GET" })
                setSubCategories([])
                setCurrentSubCategory(null)
            }
            setCurrentCompoundCategory(null)
            if(compound) setCompound(false)
        }
    }, [current])

    useEffect(() => {
        setSubCategories([])
        setCurrentSubCategory(null)
        if(compound){
            setLoading(true)
            // console.log('Loading compound true')
            asyncFetch({ url: "/compound-categories", type: "compoundCategory", method: "GET" })
        }
    }, [compound])

    useEffect(() => {
        if(currentSubCategory !== null){
            setLoading(true)
            // console.log('Loading currentSubCategory true')
            asyncFetch({ url: "/category-articles/" + currentSubCategory, type: "articles", method: "GET" })
            if(compound) setCompound(false)
        }
    }, [currentSubCategory])

    useEffect(() => {
        if(currentCompoundCategory !== null){
            setLoading(true)
            // console.log('Loading currentCompoundCategory true')
            asyncFetch({ url: "/category-articles/" + currentCompoundCategory, type: "articles", method: "GET" })
            setCurrent(null)
        }
    }, [currentCompoundCategory])

    const fetchCategories = (id, type) => {
        setFetchCategoryType(type)
        setCurrent(id)
    }

    return (
        <SecondLayout v2 cart menu>
            <Container>
                <div className="order-page">
                    <div className="list-categories mt-5">
                        <div onClick={ () => setCompound(true) } className="categories-composer">A Composez</div>

                        {(categories.length > 0) &&
                            <div className="list-composer">
                                <ul>{categories.map((category, index) => <li className={ (category.id === current) ? 'active' : null } key={index} onClick={ () => (category.count_childs === 0) ? fetchCategories(category.id, 'articles') : fetchCategories(category.id, 'categories') }>{ category.name }</li>)}</ul>
                            </div>
                        } 

                    </div>
                    {
                        (!compound) ?
                            (subCategories.length > 0) &&
                                <div className="sub-categories">
                                    <ul>{subCategories.map((sub, index) => <li className={ (sub.id === currentSubCategory) ? 'active' : null } key={index} onClick={ () => setCurrentSubCategory(sub.id) }>{ sub.name }</li>)}</ul>
                                </div>
                        :
                        (compoundCategory.length > 0) &&
                            <div className="sub-categories">
                                <ul>{compoundCategory.map((sub, index) => <li className={ (sub.id === currentCompoundCategory) ? 'active' : null } key={index} onClick={ () => setCurrentCompoundCategory(sub.id) }>{ sub.name }</li>)}</ul>
                            </div>
                    }
                    <div className="row mb-5 mt-3">
                        <div className="list-articles col-md-8 pr-md-5">
                            {
                                (loading) ? 
                                    <div className="my-5"><Loading /></div>
                                :
                                    (articles.length > 0) ?
                                        articles.map((article, index) => {
                                            if(!compound) return <HorizontalArticle addToCart={true} key={index} {...article} />
                                            return <HorizontalArticle isCompound={compound} selection={true} key={index} {...article} />
                                        })
                                    : 
                                        <h5 className="my-4">Aucun article trouvé</h5>
                            }
                        </div>

                        { (isDesktop) && <CartData /> }
                        
                    </div>
                </div>
            </Container>
            
        </SecondLayout>
    )
}

export default Order