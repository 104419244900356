import React from 'react'
import { Link } from 'react-router-dom'

import Container from './Container'

import Carte116 from './../assets/img/carte-116.png'
import CarteRituel from './../assets/img/carte-Ritteul.png'
import CartePapote from './../assets/img/papote.png'

const Stores = () => {
    return (
        <div className="formule-articles">
            <Container>
                <div className="row">
                    <div className="col-md-4 formule-list mb-4 mb-md-0 single-formule-article">
                        <div className="article-image"><Link to='/commander'><img src={ Carte116 } alt="" /></Link></div>
                        <h4><Link to='/commander'>Carte 116</Link></h4>
                        <p>Crêpe salée/sucrées , bar à salade, bar à pâte ...</p>
                    </div>
                    <div className="col-md-4 formule-list mb-4 mb-md-0 single-formule-article">
                        <div className="article-image"><Link to='/commander'><img src={ CarteRituel } alt="" /></Link></div>
                        <h4><Link to='/commander'>Carte Rituel</Link></h4>
                        <p>Envie d'une petite plat cuisiné</p>
                    </div>
                    <div className="col-md-4 formule-list mb-4 mb-md-0 single-formule-article">
                        <div className="article-image"><Link to='/commander'><img src={ CartePapote } alt="" /></Link></div>
                        <h4><Link to='/commander'>Papote</Link></h4>
                        <p>Thé , boissons chaudes, café ..</p>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Stores
