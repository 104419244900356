import React from 'react'
import { Link } from 'react-router-dom'
import { Url } from './../units/Units'
import { useMediaQuery } from 'react-responsive'

const Menu = () => {
    const isDesktop = useMediaQuery({ query: '(min-device-width: 768px)' }) 

    return (
        <div className="menu">
            {!isDesktop && <Link to="/">Accueil</Link>}
            <Link to="/concept">Concept</Link>
            <a target="_blank" href={Url+ '/menu.pdf'}>Menu</a>
            {!isDesktop && <Link to="/commander">Commander</Link>}
            <Link to="/nous-trouver">Nous Trouver</Link>
        </div>
    )
}

export default Menu
