import React, { useRef, useState, useContext, useEffect } from 'react'

import SingleAttribute from './SingleAttribute'
import { CartContext } from './../context/CartContext'


const CollapseAttribute = ({ items, is_unlimited, id, title, activateQuantity, max, addDetails, removeDetails, requiredSelection, attribute }) =>  { //
    const { cart } = useContext(CartContext)
    const [status, setStatus] = useState(false)
    const [limited, setLimited] = useState(false)
    const [required, setRequired] = useState(false)
    const [count, setCount] = useState(0)
    const [elementID, setElementID] = useState(0)

    const addOptions = (back) => {
        if(!is_unlimited){
            if(back){
                if(count < max) setCount(count + 1)
                if(count === max-1) setLimited(true)    
            }else{
                if(count > 0)setCount(count - 1)
                if(count !== max-1) setLimited(false)  
            }
        }else{
            if(back) setCount(count + 1)
            else setCount(count - 1)
        }
    }

    useEffect(() => {
        setElementID(document.getElementById('element-collapse-'+id));
    }, [id]);

    useEffect(() => {
        setRequired(requiredSelection)
    }, [])

    const handleScrollTo = event => {
        elementID.scrollIntoView(true);
        setStatus(!status)
    };

    useEffect(() => {
        setStatus(false)
        setLimited(false)
        setCount(0)
    }, [cart])

    const add = (id) => { addDetails(id, attribute) }
    const remove = (id) => { removeDetails(id, attribute) }

    return (
        <div className="selection-item" id={ 'element-collapse-'+id }>
            <div className={ (status) ? 'active selection-item-title' : 'selection-item-title' } onClick={ handleScrollTo }>
                <div>
                    <h4>{title}</h4>
                    <p>Veuillez selectionner {max} {title}</p>
                </div>
                <p>{ count + "/" + max }</p>
            </div>
            <div className={ (status) ? 'active selection-item-content' : 'selection-item-content' }>
                <div className="attributes-grid">
                    {
                        items.map((row, index) =>
                            <SingleAttribute 
                                addDetails={add} 
                                removeDetails={remove} 
                                key={index} 
                                limited={ limited } 
                                addOptions={ addOptions } 
                                activateQuantity={ activateQuantity }
                                {...row} 
                            />
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default CollapseAttribute
