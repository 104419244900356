import { useState, useCallback } from 'react'
import { BaseUrl } from './Units'
import Cookies from 'js-cookie'

const useFetch = () => {
    const [extraData, setExtraData] = useState(null)
    const [response, setResponse] = useState(null)
    const [fetchType, setFetchType] = useState(null)
    const [dataLoading, setDataLoading] = useState(true)
    
    const [flashError, setFlashError] = useState(null)
    const [flashSuccess, setFlashSuccess] = useState(null)

    const asyncFetch = useCallback(async ({ url, type, ...options }) => {
        setFetchType(type)
        setDataLoading(true)

        try {
            const res = await fetch(BaseUrl + url, {headers: {'Accept': 'application/json','Content-Type': 'application/json','apikey': 'adWfaHiseN0/h$.','Authorization': 'Bearer ' + Cookies.get('166-token')}, ...options})
            const json = await res.json()

            if(json.status){
                setFlashError(null)
                if(json.message) setFlashSuccess({[type]: json.message})
                setResponse(json.data)
                // console.log(json.data)
                setDataLoading(false)
            }else{
                // console.log('type', json)
                setFlashSuccess(null)
                if(json.message){
                    if(type !== 'register') setFlashError({[type]: json.message})
                    else setFlashError(json.message)
                }
                if(json.data) setExtraData(json.data)
                setDataLoading(false)
            }

        } catch (error) {
            // console.log(error)
        }
    }, []);
    
    return [
        asyncFetch,
        {
            extraData,
            response,
            fetchType,
            dataLoading,

            flashError,
            flashSuccess
        }
    ];
};


export default useFetch
