import React from 'react'
import { Link } from 'react-router-dom'

const Button = ({ link, title }) => {
    return (
        <div className="button">
            <Link to={link}>{title}</Link>
        </div>
    )
}

export default Button
