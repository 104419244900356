import React, { useState, useContext } from 'react'

import { CartContext } from './../../context/CartContext'
import CartIcon from './../../assets/img/cart-icon.svg'
import CloseMenuIcon from './../../assets/img/menuClose.svg'
import CartData from './../CartData'

const ToggleCart = () => {
    const [ state, setState ] = useState(false)
    const { cart } = useContext(CartContext)

    const openCart = () => {
        setState(true)
    }
    const closeCart = () => {
        setState(false)
    }

    return (
        <>
            <div onClick={ openCart } className="open-cart">
                <img src={ CartIcon } alt="cart icon" /> <span>{ (cart && cart.articles) ? cart.articles.length : 0 }</span>
            </div>
            <div className={ (state) ? 'cart-mobile active' : 'cart-mobile' }>
                <img src={ CloseMenuIcon } className="close-cart-mobile" onClick={ closeCart } alt="Close cart" />
                <CartData />
            </div>
        </>
    )
}

export default ToggleCart
