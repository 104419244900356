import React, { useState, useEffect, useContext } from 'react'
import { CartContext } from './../context/CartContext'
import { UserContext } from './../context/UserContext'

import ListMessagesErrors from './../components/ListMessagesErrors'

const FlashMessage = () => {
    const { flashErrorUser, setFlashErrorUser, flashSuccessUser, setFlashSuccessUser } = useContext(UserContext)
    const { flashErrorCart, setFlashErrorCart, flashSuccessCart, setFlashSuccessCart } = useContext(CartContext)

    const [ stateError, setStateError ] = useState(null)
    const [ stateSuccess, setStateSuccess ] = useState(null)

    useEffect(() => {
        if(flashErrorUser) setStateError(flashErrorUser)
        if(flashErrorCart) setStateError(flashErrorCart)
        if(setStateSuccess){
            let timer = setTimeout(() => {
                setStateError(null)
                setFlashErrorUser(null)
                setFlashErrorCart(null)
            }, 3200)
            
            return () => clearTimeout(timer)
        }
    }, [flashErrorUser, flashErrorCart])

    useEffect(() => {
        if(flashSuccessUser) setStateSuccess(flashSuccessUser)
        if(flashSuccessCart) setStateSuccess(flashSuccessCart)
        if(setStateSuccess){
            let timer = setTimeout(() => {
                setStateSuccess(null)
                setFlashSuccessUser(null)
                setFlashSuccessCart(null)
            }, 3200)

            return () => clearTimeout(timer)
        }

    }, [flashSuccessUser, flashSuccessCart])

    if(stateError) return <div className="flash-message alert alert-danger"><ListMessagesErrors messages={stateError} /></div>
    if(stateSuccess) return <div className="flash-message alert alert-success"><ListMessagesErrors messages={stateSuccess} /></div>

    return null
}

export default FlashMessage
