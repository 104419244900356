import React, { useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'

import Layout from './../includes/Layout'
import Container from './../components/Container'
import Logo from './../components/Logo'
import { CartContext } from './../context/CartContext'

const OrderSuccess = () => {
    const { setRedirect } = useContext(CartContext)
    useEffect(() => {
        setRedirect(false)
    }, [])

    return (
        <Layout>
            <Container>
                <div className="success-page">
                    <Logo />
                    <h1>Merci d'avoir commander chez nous</h1>
                    <Link to="/mes-commandes"><span>Mes Commandes</span></Link>
                </div>
            </Container>
        </Layout>
    )
}

export default OrderSuccess
