import React, { useContext } from 'react'
import FacebookLoginWithButton from 'react-facebook-login'

import { UserContext } from './../context/UserContext'

const FacebookLogin = () => {
    const { SignUpFacebook, setLoadingForm } = useContext(UserContext)

    const responseFacebook = (response) => {
        if(response){
            setLoadingForm(true)
            SignUpFacebook(response)
        }
        
    }
    // const componentClicked = () => {
    //     console.log( "Clicked!" )
    // }

    return (
        <div className="facebook-login">
            <FacebookLoginWithButton
                appId="312896763321260"
                autoLoad={false}
                fields="name,email,picture"
                // onClick={componentClicked}
                callback={responseFacebook}
                icon="fa-facebook"
            />
        </div>
    )
}

export default FacebookLogin
