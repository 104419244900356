import React, { createContext, useState, useEffect } from 'react'
import useFetch from './../units/useFetch'
import Cookies from 'js-cookie'

export const UserContext = createContext()

export const UserContextProvider = ({ children }) => {
    const [ flashErrorUser, setFlashErrorUser ] = useState(null)
    const [ flashSuccessUser, setFlashSuccessUser ] = useState(null)

    const [ redirect, setRedirect ] = useState(false)
    const [ auth, setAuth ] = useState(false)
    const [ user, setUser ] = useState(null)
    const [ loadingForm, setLoadingForm ] = useState(false)
    const [ asyncFetch, { response, fetchType, success, setSuccess, dataLoading, flashError, flashSuccess } ] = useFetch()

    useEffect(() => {
        if(Cookies.get('166-token')){
            setAuth(true)
            asyncFetch({ url: '/get-user', type: 'getUser', method: 'GET' })
        }
    }, [])

    useEffect(() => {
        switch(fetchType){
            case 'register':
                Cookies.set('166-token', response.access_token, { expires: 3 })
                asyncFetch({ url: '/get-user', type: 'getUser', method: 'GET' })
            break
            case 'login':
                Cookies.set('166-token', response.access_token, { expires: 3 })
                asyncFetch({ url: '/get-user', type: 'getUser', method: 'GET' })
            break
            case 'getUser':
                setAuth(true)
                setUser(response)
                setLoadingForm(false)
            break
            case 'forgot-password':
                setLoadingForm(false)
                setRedirect(true)
            break
            case 'reset-password':
                setLoadingForm(false)
                setRedirect(true)
            break
            case 'UpdateProfile':
                setUser(response)
                setLoadingForm(false)
            break
            case 'UpdatePassword':
                setLoadingForm(false)
            break
            default :
                console.log("type")
        }
    }, [response])

    // Handle Errors
    useEffect(() => {
        //if(dataLoading) 
        setLoadingForm(false)
        if(fetchType === 'getUser'){
            Cookies.remove('166-token')
            setAuth(false)
        }
        setFlashErrorUser(flashError)
    }, [flashError])
    useEffect(() => {
        //if(dataLoading) 
        setFlashSuccessUser(flashSuccess)
    }, [flashSuccess])

    const SignUpFacebook = (credentials) => {
        asyncFetch({ url: '/login-facebook', type: 'register', method: 'POST', body: JSON.stringify(credentials) })
    }

    const SignUpGoogle = (credentials) => {
        asyncFetch({ url: '/login-google', type: 'register', method: 'POST', body: JSON.stringify(credentials) })
    }

    const SignUp = (credentials) => {
        asyncFetch({ url: '/register', type: 'register', method: 'POST', body: JSON.stringify(credentials) })
    }

    const SignIn = (credentials) => {
        asyncFetch({ url: '/login', type: 'login', method: 'POST', body: JSON.stringify(credentials) })
    }

    const ForgotPassword = (credentials) => {
        asyncFetch({ url: '/forgot-password', type: 'forgot-password', method: 'POST', body: JSON.stringify(credentials) })
    }

    const ResetPassword = (credentials, token) => {
        credentials['token'] = token
        asyncFetch({ url: '/reset-password', type: 'reset-password', method: 'POST', body: JSON.stringify(credentials) })
    }

    const SignOut = () => {
        Cookies.remove("166-token");
        localStorage.removeItem("166-cart");
        localStorage.removeItem("coupon");
        setAuth(false)
        setUser(null)
    }

    const UpdateProfile = (data) => {
        asyncFetch({ url: '/update-user', type: 'UpdateProfile', method: 'POST', body: JSON.stringify(data) })
    }

    const UpdatePassword = (data) => {
        asyncFetch({ url: '/update-user-password', type: 'UpdatePassword', method: 'POST', body: JSON.stringify(data) })
    }


    return (
        <UserContext.Provider value={{ flashErrorUser, setFlashErrorUser, flashSuccessUser, redirect, setRedirect, setFlashSuccessUser, auth, user, SignUp, SignUpFacebook, SignUpGoogle, SignIn, ForgotPassword, ResetPassword, SignOut, dataLoading, UpdateProfile, UpdatePassword, loadingForm, setLoadingForm, success, setSuccess }}>
            {children}
        </UserContext.Provider>
    )
}