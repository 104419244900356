import React, { useContext } from 'react'

import Container from './Container'
import { UserContext } from './../context/UserContext'

const PageCover = ({ title, bg, logout }) => {
    const { auth, SignOut } = useContext(UserContext)

    return (
        <div className="page-cover" style={{ backgroundImage: 'url('+bg+')' }}>
            <Container>
                <h1>{ title }</h1>
                { (logout && auth) && <button onClick={SignOut}>Se déconnecter</button>}
            </Container>
        </div>
    )
}

export default PageCover
