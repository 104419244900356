import React, { useState, useContext } from 'react'
import { CartContext } from './../context/CartContext'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'


const addressSchema = Yup.object().shape({
    street: Yup.string().required('Champ obligatoire'),
    area: Yup.string().required('Champ obligatoire'),
    block: Yup.string().required('Champ obligatoire'),
    code_postal: Yup.string().required('Champ obligatoire')
})

const AddAddress = ({ submitAddress }) => {
    const { subAddress } = useContext(CartContext)

    return (
        <Formik
            initialValues={{ street: (subAddress && subAddress.street) ? subAddress.street : '', area: (subAddress && subAddress.area) ? subAddress.area : '', block: (subAddress && subAddress.block) ? subAddress.block : '', code_postal: (subAddress && subAddress.postalCode) ? subAddress.postalCode : '' }}
            validationSchema={addressSchema}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false)
                submitAddress(values)
            }}
        >
            {({
                values,
                handleChange,
                handleBlur,
                isSubmitting,
            }) => (
                <Form className="form-sign">
                    <div className="form-group">
                        <label htmlFor="street">Street</label>
                        <input
                            type="text"
                            className="form-control"
                            name="street"
                            id="street"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.street}
                        />
                        <ErrorMessage name="street" component="div" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="area">Zone</label>
                        <input
                            type="text"
                            className="form-control"
                            name="area"
                            id="area"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.area}
                        />
                        <ErrorMessage name="area" component="div" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="block">Secteur</label>
                        <input
                            type="text"
                            className="form-control"
                            name="block"
                            id="block"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.block}
                        />
                        <ErrorMessage name="block" component="div" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="code_postal">Code postal</label>
                        <input
                            type="text"
                            className="form-control"
                            name="code_postal"
                            id="code_postal"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.code_postal}
                        />
                        <ErrorMessage name="code_postal" component="div" />
                    </div>
                    <div className="form-group mt-4 mb-0 mb-0">
                        <button type="submit" className="btn" disabled={isSubmitting}>Ajouter</button>
                    </div>

                </Form>
            )}
        </Formik>
    )
}

export default AddAddress
