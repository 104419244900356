import React from 'react'

import Layout from './../includes/Layout'
import PageCover from './../components/PageCover'
import InstagramGallery from './../components/InstagramGallery'

import Container from './../components/Container'

import page404 from './../assets/img/404-page.jpg'

import PageCoverImage from './../assets/img/page-cover.jpg'

const ErrorPage = () => {
    return (
        <Layout>
            <PageCover title="Page non trouvée" bg={ PageCoverImage } />
            
            <Container>
                <div className="error-page text-center my-5 py-5">
                    <img src={page404} alt="Page 404" />
                </div>
            </Container>
        </Layout>
    )
}

export default ErrorPage