import React from 'react'

import Dailer from './../assets/img/dialer.svg'

function ContactDailer() {
    return (
        <div className="contact-dailer">
            <a href="tel:+212528820312"><img src={ Dailer } alt="dailer" /></a>
        </div>
    )
}

export default ContactDailer
