import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Eye from './../../assets/img/eye.svg'

import Loading from './../../components/Loading'
import ProfileLayout from './../../includes/ProfileLayout'
import useFetch from './../../units/useFetch'

const MyOrders = () => {
    const [ loading, setLoading ] = useState(true)
    const [ orders, setOrders ] = useState([])
    const [ asyncFetch, { response } ] = useFetch()

    useEffect(() => {
        asyncFetch({ url: '/orders', type: 'orders', method: 'GET',  })
    }, [])

    useEffect(() => {
        setOrders(response)
        if(response) setLoading(false)
    }, [response])

    const statusWrapper = (status) => {
        if(status === 'preparation') return <span className="text-red">En préparation</span>
        if(status === 'ready') return <span className="text-warning">Prêt</span>
        if(status === 'delivered') return <span className="text-success">Livrée</span>
        if(status === 'cancel') return <span className="text-gray">Annulée</span>
        return <span className="text-info">Nouveaux</span>
    }

    return (
        <ProfileLayout>
            {
                (!loading) ? 
                        ( orders.length > 0 ) ?
                            orders.map((item, index) =>
                                <div key={index} className="row-order">
                                    <div className="order-id">#{item.id}</div>
                                    <div className="order-items">{item.items} articles</div>
                                    <div className="order-total">{item.total} DH</div>
                                    <div className="order-status">{statusWrapper(item.status)}</div>
                                    <div className="order-check">
                                        <Link to={ '/mes-commandes/' + item.id }><img src={ Eye } alt="eye" /></Link>
                                    </div>
                                </div>
                            )
                        :
                            <h2 className="my-5 text-center text-dark">vous n'avez encore aucune commande</h2>
                : 
                    <div className="my-5 text-center"><Loading /></div>
            }
        </ProfileLayout>
    )
}

export default MyOrders
