import React from 'react'
import { useMediaQuery } from 'react-responsive'

import Header from './Header'
import HeaderMobile from './../components/Mobile/HeaderMobile'
import FlashMessage from './../components/FlashMessage'
import ContactDailer from './../components/ContactDailer'

const SecondLayout = ({ children, v2=false, url=null, menu=false, cart }) => {
    const isDesktop = useMediaQuery({ query: '(min-device-width: 768px)' }) 
    return (
        <>
            { (isDesktop) ? <Header /> : <HeaderMobile v2={v2} url={url} showMenu={menu} showCart={cart} /> }
            <FlashMessage />
            { children }
            {/*<ContactDailer />*/}
        </>
    )
}

export default SecondLayout