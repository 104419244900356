// export const BaseUrl = 'http://116-store.test/api'
// export const Url = 'http://116-store.test'

export const BaseUrl = 'https://admin.116.ma/api'
export const Url = 'https://admin.116.ma'

export const InstagramLink = "https://www.instagram.com/116agadir/"
export const FacebookLink = "https://www.facebook.com/116saladebar/"
export const TripadvisorLink = "https://www.tripadvisor.com/Restaurant_Review-g293731-d6707574-Reviews-Le_116_creperie_et_bar_a_salade-Agadir_Souss_Massa_Draa_Region.html?m=19905"

export const GoogleMapToken = 'AIzaSyBvzkkdirdejZa90gR0HFNlr_YFF86LwtE'

export const MainViewSlideshow = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 600,
            settings: {
                fade: true,
                dots: false,
            }
        }
    ]
};

export const singleViewSlideshow = {
    dots: true,
    infinite: true,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    adaptiveHeight: true
};

export const MainViewArticles = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    arrows: false,
    slidesToScroll: 1,
    autoplay:true,
    speed: 700,
    autoplaySpeed: 7000,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
            }
        }
    ]
 
};

export const carouselViewSlideshow = {
    dots: false,
    centerMode: true,
    centerPadding: '60px',
    infinite: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 8000,
    slidesToShow: 3,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
};

export const Desktop = '769px'
export const Mobile = '768px'
