import React from 'react'
import { Link } from 'react-router-dom'

import PageCoverImage from './../assets/img/page-cover.jpg'

import Layout from './Layout'
import Container from './../components/Container'
import PageCover from './../components/PageCover'

const ProfileLayout = ({ children }) => {
    return (
        <Layout>
            <PageCover title="Page de profil" bg={ PageCoverImage } logout={true} />
            <Container>
                <div className="my-md-5 py-5">
                    <div className="row">
                        <div className="col-md-4 profile-menu">
                            <ul>
                                <li><Link to="/profile">Compte</Link></li>
                                <li><Link to="/mot-de-passe">Mot de passe</Link></li>
                                <li><Link to="/mes-commandes">Mes commandes</Link></li>
                            </ul>
                        </div>
                        <div className="col-md-8">{ children }</div>
                    </div>
                </div>
            </Container>
        </Layout>
    )
}

export default ProfileLayout
