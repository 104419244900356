import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { UserContext } from './../context/UserContext'

import Avatar from './../assets/img/avatar.svg'

const SignStatus = ({ handleLogin }) => {
    const { auth, user } = useContext(UserContext)

    return (
            <div className="sign-status">
                <img src={ Avatar } alt="" />
                {
                    (auth) ?
                        <Link to="/profile">{ (user) && user.name }</Link>
                    : 
                        <button onClick={ handleLogin }>Connectez-vous / Inscrivez-vous</button>
                }
            </div>
        
    )
}

export default SignStatus
