import React, { useContext } from 'react'
import GoogleLogin from 'react-google-login'

import { UserContext } from './../context/UserContext'

const GoogleEmailLogin = () => {
    const { SignUpGoogle, setLoadingForm } = useContext(UserContext)

    const responseGoogle = (response) => {
        if(response){
            if(response && response.profileObj){
                setLoadingForm(true)
                SignUpGoogle(response)
            }
        }
        
    }
    // const responseErrorGoogle = () => {
    //     console.log( "error google" )
    // }

    return (
        <div className="google-login">
            <GoogleLogin
                clientId="427194381824-1dd2s18bgtnbpbhs1g790ntkv0qpisf5.apps.googleusercontent.com"
                buttonText="Login"
                onSuccess={responseGoogle}
                cookiePolicy={'single_host_origin'}
                // onFailure={responseErrorGoogle}
            />
        </div>
    )
}

export default GoogleEmailLogin
