import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/style.css'
import './assets/responsive.css'

import { ApplicationContextProvider } from './context/ApplicationContext'
import { UserContextProvider } from './context/UserContext'
import { CartContextProvider } from './context/CartContext'
import ProtectedRoute from './includes/ProtectedRoute'

import Home from './pages/Home'
import Concept from './pages/Concept'
import Contact from './pages/Contact'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Order from './pages/Order'
import Composez from './pages/Composez'
import ErrorPage from './pages/ErrorPage'

import Login from './pages/Login'
import Register from './pages/Register'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'

import Profile from './pages/profile/Profile'
import ChangePassword from './pages/profile/ChangePassword'
import MyOrders from './pages/profile/MyOrders'
import SingleOrder from './pages/profile/SingleOrder'
import OrderSuccess from './pages/OrderSuccess'
import OrderFailed from './pages/OrderFailed'

function App() {
  return (
    <ApplicationContextProvider>
      <UserContextProvider>
        <CartContextProvider>
          <Router>
            <Switch>
              <Route exact path="/"><Home /></Route>
              <Route path="/login"><Login /></Route>
              <Route path="/register"><Register /></Route>
              <Route path="/forgot-password"><ForgotPassword /></Route>
              <Route path="/reset-password/:token"><ResetPassword /></Route>

              <Route path="/commander"><Order /></Route>
              <Route exact path="/composez/:slug"><Composez /></Route>

              <Route path="/concept"><Concept /></Route>
              <Route path="/nous-trouver"><Contact /></Route>
              <Route path="/privacy-policy"><PrivacyPolicy /></Route>

              <ProtectedRoute path="/profile" component={ Profile } />
              <ProtectedRoute path="/mot-de-passe" component={ ChangePassword } />
              <ProtectedRoute exact path="/mes-commandes" component={ MyOrders } />
              <ProtectedRoute path="/mes-commandes/:id" component={ SingleOrder } />
              <ProtectedRoute path="/order-success" component={ OrderSuccess } />
              <ProtectedRoute path="/order-failed" component={ OrderFailed } />

              <Route><ErrorPage /></Route>
            </Switch>
          </Router>

        </CartContextProvider>
      </UserContextProvider>
    </ApplicationContextProvider>
  );
}

export default App;
