import React from 'react'

const FullWidthBackground = ({ children }) => {
    return (
        <div className="fullwidth-background">
            { children }
        </div>
    )
}

export default FullWidthBackground
