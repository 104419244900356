import React from 'react'
import ToggleIcon from './../../assets/img/toggle-light.svg'

const ToggleMenu = ({ openMenu }) => {
    return (
        <div className="toggle" onClick={ openMenu }>
            <img src={ ToggleIcon } alt="toggle-menu" />
        </div>
    )
}

export default ToggleMenu
