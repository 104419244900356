import React, { useEffect, useContext } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { UserContext } from './../../context/UserContext'

import Loading from './../../components/Loading'
import MessageError from './../../components/MessageError'
import ListMessagesErrors from './../../components/ListMessagesErrors'

import ProfileLayout from './../../includes/ProfileLayout'

const SignupSchema = Yup.object().shape({
    current_password: Yup.string().required('Champ obligatoire').min(8, 'Ce champ doit contenir au moins 8 caractères'),
    password: Yup.string().required('Champ obligatoire').min(8, 'Ce champ doit contenir au moins 8 caractères'),
    password_confirmation: Yup.string().required('Champ obligatoire').min(8, 'Ce champ doit contenir au moins 8 caractères')
})

const ChangePassword = () => {
    const { UpdatePassword, loadingForm, setLoadingForm } = useContext(UserContext)

    return (
        <ProfileLayout>
            <Formik
                initialValues={{ current_password: '', password: '', password_confirmation: '' }}
                validationSchema={SignupSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        setLoadingForm(true)
                        setSubmitting(false);
                        UpdatePassword(values)

                        values.current_password = ''
                        values.password = ''
                        values.password_confirmation = ''
                    }, 400);
                    // clearTimeout(timer)
                }}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    isSubmitting,
                }) => (
                    <Form className="form-sign">
                        <div className="form-group">
                            <label htmlFor="current_password">Mot de passe actuel</label>
                            <input
                                type="password"
                                className="form-control"
                                name="current_password"
                                id="current_password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.current_password}
                            />
                            <ErrorMessage name="current_password" component="div" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Mot de passe</label>
                            <input
                                type="password"
                                className="form-control"
                                name="password"
                                id="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                            />
                            <ErrorMessage name="password" component="div" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password_confirmation">Confirmation mot de passe</label>
                            <input
                                type="password"
                                className="form-control"
                                name="password_confirmation"
                                id="password_confirmation"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password_confirmation}
                            />
                            <ErrorMessage name="password_confirmation" component="div" />
                        </div>
                        <div className="form-group mt-4 mb-0 mb-0">
                            <button type="submit" className="btn" disabled={isSubmitting}>Enregistrer</button>
                        </div>

                        { (loadingForm) && <div className="mt-3 text-center"><Loading other="mt-4" /></div>  }

                    </Form>
                )}
            </Formik>
        </ProfileLayout>
    )
}

export default ChangePassword
