import React from 'react'
import MessageError from './MessageError'

const ListMessagesErrors = ({ messages }) => {
    return (
        <div className="list-errors">
            { Object.keys(messages).map((key, index) => <MessageError key={index} message={messages[key]} />) }
        </div>
    )
}

export default ListMessagesErrors
