import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import Eye from './../../assets/img/eye.svg'

import Loading from './../../components/Loading'
import ProfileLayout from './../../includes/ProfileLayout'
import useFetch from './../../units/useFetch'

const SingleOrder = () => {
    const { id } = useParams();

    const [ loading, setLoading ] = useState(true)
    const [ order, setOrder ] = useState([])
    const [ asyncFetch, { response } ] = useFetch()

    useEffect(() => {
        asyncFetch({ url: '/order/' + id, type: 'orders', method: 'GET' })
    }, [])

    useEffect(() => {
        setOrder(response)
        if(response) setLoading(false)
    }, [response])

    const statusWrapper = (status) => {
        if(status === 'preparation') return <span className="text-red">En préparation</span>
        if(status === 'ready') return <span className="text-warning">Prêt</span>
        if(status === 'delivered') return <span className="text-success">Livrée</span>
        if(status === 'cancel') return <span className="text-gray">Annulée</span>
        return <span className="text-info">Nouveaux</span>
    }

    return (
        <ProfileLayout>
            {
                ( order && !loading ) ? 
                    <div className="single-order">
                        <div className="row">
                            <div className="col-md-6">
                                <h5>Les détails de la commande</h5>
                                <table className="table mb-4">
                                    <tbody>
                                        { (order['id']) && <tr><th>N* commande</th><td>#{ order['id'] }</td></tr> }
                                        { (order['type']) && <tr><th>Type</th><td>{ order['type'] }</td></tr> }
                                        { (order['timing']) && <tr><th>Heure</th><td>{ order['timing'] }</td></tr> }
                                        { (order['status']) && <tr><th>Statut</th><td>{ statusWrapper(order['status']) }</td></tr> }
                                        { (order['coupon']) ? <tr><th>Remise</th><td>{ order['coupon'] } DH</td></tr> : null }
                                        { (order['total']) && <tr><th>Total</th><td>{ order['total'] } DH</td></tr> }
                                    </tbody>
                                </table>
                            </div>
                            { (order['address']) &&
                                <div className="col-md-6">
                                    <h5>Adresse du client</h5>
                                    <table className="table mb-4">
                                        <tbody>
                                        {    (order['address']['street']) ? <tr><th>Rue</th><td>{ order['address']['street'] }</td></tr> : null }
                                            { (order['address']['area']) ? <tr><th>Quartier</th><td>{ order['address']['area'] }</td></tr> : null }
                                            { (order['address']['block']) ? <tr><th>Bloc</th><td>{ order['address']['block'] }</td></tr> : null }
                                            { (order['address']['codepostal']) ? <tr><th>Postal</th><td>{ order['address']['codepostal'] }</td></tr> : null }
                                            { (order['address']['addition_infos']) ? <tr><th>Information additionnelle</th><td>{ order['address']['addition_infos'] }</td></tr> : null }
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                        <div className="order-items">
                            { (order['items']) &&
                                order['items'].map((item, key) => 
                                    <div key={key} className="order-item">
                                        {
                                            (item['image']) && (
                                                <div className="order-image">
                                                    <img src={item['image']} alt={item['title']} />
                                                </div>
                                            )
                                        }
                                        <div className="order-description">
                                            <h3 className="order-title">{item['title']}</h3>
                                            <div className="order-price mb-2"><strong>Prix:</strong> {item['price']} DH &nbsp;&nbsp; { 'x'+item['quantity'] }</div>
                                            <div className="order-details">
                                                { 
                                                    Object.keys(item['options']).map((key, index) => 
                                                        Object.keys(item['options'][key]).map((key1, index) => 
                                                            <p className="mb-1" key={ index }>
                                                                {item['options'][key][key1]['name']}
                                                                { (item['options'][key][key1]['price'] && item['options'][key][key1]['price'] !== 0) ? ' (+' + item['options'][key][key1]['price'] + ' DH)' : null }
                                                            </p>
                                                        )
                                                    ) 
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        { (order['comment']) && 
                            <p className="mt-5 text-dark border p-3">
                                <strong className="d-block mb-2">Commentaire</strong>
                                { order['comment'] }
                            </p>
                        }
                    </div>
                :
                    <div className="my-5 text-center"><Loading /></div>
            }
        </ProfileLayout>
    )
}

export default SingleOrder
