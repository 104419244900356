import React, { useEffect, useContext } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { UserContext } from './../context/UserContext'
import Loading from './Loading'

const SignupSchema = Yup.object().shape({
    email: Yup.string().email('Adresse e-mail invalide').required('Champ obligatoire')
})

const ForgotPasswordForm = () => {
    const { ForgotPassword, loadingForm, setLoadingForm } = useContext(UserContext)

    return (        
        <Formik
            initialValues={{ email: '' }}
            validationSchema={SignupSchema}
            onSubmit={(values, { setSubmitting }) => {
                setLoadingForm(true)
                setTimeout(() => {
                    setSubmitting(false);
                    ForgotPassword(values)
                }, 400);
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <Form className="form-sign">
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            name="email"
                            id="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                        />
                        <ErrorMessage name="email" component="div" />
                    </div>
                    <div className="form-group mt-4 mb-0 mb-0">
                        <button type="submit" className="btn" disabled={isSubmitting}>Envoyer</button>
                    </div>

                    { (loadingForm) && <div className="mt-3 text-center"><Loading other="mt-4" /></div>  }
                </Form>
            )}
        </Formik>
    )
}

export default ForgotPasswordForm