import React, { useContext } from 'react'
import { Redirect, useParams } from 'react-router-dom';

import Layout from './../includes/Layout'
import Cookies from 'js-cookie'
import PageCoverImage from './../assets/img/page-cover.jpg'

import Container from './../components/Container'
import PageCover from './../components/PageCover'
import ResetPasswordForm from './../components/ResetPasswordForm'
import FullPageLoading from './../components/FullPageLoading'

import { UserContext } from './../context/UserContext'

const ResetPassword = ({ history }) => {
    const { auth, dataLoading, redirect, setRedirect } = useContext(UserContext)
    const { token } = useParams();

    if(Cookies.get('166-token')){
        if(dataLoading) return <FullPageLoading />
        if(auth) return <Redirect to='/' />
    }

    if(!token) return <Redirect to="/" />

    if(redirect){
        setRedirect(false)
        return <Redirect to="/login" />
    }

    return (
        <Layout>
            <PageCover title="Réinitialiser le mot de passe" bg={ PageCoverImage } />
        
            <Container>
                <div className="row">
                    <div className="col-md-8 col-lg-6 offset-md-2 offset-lg-3">
                        <div className="wrapper-log">
                            <ResetPasswordForm token={token} />
                        </div>
                    </div>
                </div>
            </Container>
        </Layout>
    )
}

export default ResetPassword