import React, { useState, useContext, Fragment, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import { CartContext } from './../context/CartContext'
import { UserContext } from './../context/UserContext'
import { ApplicationContext } from './../context/ApplicationContext'

import EcommerceBag from './../assets/img/ecommerce-bag.svg'
import ApplyCoupon from './ApplyCoupon'
import Login from './LoginForm'
import Register from './RegisterForm'
import SignStatus from './SignStatus'
import PoopUp from './PoopUp'
import Loading from './Loading'
import ApplyOrderType from './ApplyOrderType'
import OrderType from './OrderType'
import FacebookLogin from './FacebookLogin'
import GoogleEmailLogin from './GoogleEmailLogin'
import CommentCommande from './CommentCommande'
import PickTime from './PickTime'
import ApplyTiming from './ApplyTiming'
import AddAddress from './AddAddress'
import ApplyAddress from './ApplyAddress'
import Payment from './Payment'

import GoogleMap from './GoogleMap'

const CartData = () => {
    const isDesktop = useMediaQuery({ query: '(min-device-width: 768px)' }) 

    const [login, setLogin] = useState(false)
    const [register, setRegister] = useState(false)
    const [typeStatus, setTypeStatus] = useState(false)
    const [commentStatus, setCommentStatus] = useState(false)
    const [timingStatus, setTimingStatus] = useState(false)
    const [mapStatus, setMapStatus] = useState(false)
    const [addressStatus, setAddressStatus] = useState(false)

    const { status } = useContext(ApplicationContext)

    const { auth } = useContext(UserContext)
    const { cart, comment, setComment, type, setType, setAddress, setSubAddress, setAttitude, setTiming, loadingForm, handleUpdateCart, handleCommande, setLoadingForm, redirect } = useContext(CartContext)
    
    const handleLogin = () => setLogin(!login)
    const handleOrderType = () => setTypeStatus(!typeStatus)
    const handleOrderComment = () => setCommentStatus(true)
    const handleOrderTiming = () => setTimingStatus(true)
    const handleOrderMap = () => setMapStatus(true)
    const handleOrderAddress = () => setAddressStatus(true)

    const handleClose = () => {
        if(login) setLogin(false)
        if(register) setRegister(false)
        if(typeStatus) setTypeStatus(false)
        if(commentStatus) setCommentStatus(false)
        if(timingStatus) setTimingStatus(false)
        if(addressStatus) setAddressStatus(false)
    }

    const submitMap = (latLat, latLng, address) => {
        setAttitude({lat: latLat, lng: latLng})
        setSubAddress(address)
        setMapStatus(false)
        setAddressStatus(true)
    }

    const submitAddress = (item) => {
        setAddress(item)
        setAddressStatus(false)
    }

    const submitTiming = (item) => {
        setTiming(item)
        setTimingStatus(false)
    }

    const submitType = (item) => {
        setType(item)
        setTypeStatus(false)
        if(item === "emporter"){
            setTimingStatus(true)
            setSubAddress(null)
            setAddress(null)
        }
        if(item === "delivery"){
            setMapStatus(true)
            setTiming(null)
        }
    }

    const submitComment = (item) => {
        setComment(item)
        setCommentStatus(false)
    }

    const changeForms = () => {
        setLogin(!login)
        setRegister(!register)
    }

    useEffect(() => {
        setLogin(false)
        setRegister(false)
    }, [auth])

    const submitCommand = () => {
        setLoadingForm(true)
        handleCommande()
    }

    if(redirect) return <Redirect to="/order-success" />
    return (
        <div className="col-md-4 checkout-card">
            { (login) && <PoopUp close={ handleClose }><Login /><h6 className="changeform" onClick={ changeForms }>Je n'ai pas de compte</h6><hr className="my-4" /><FacebookLogin /><GoogleEmailLogin /></PoopUp> }
            { (register) && <PoopUp close={ handleClose }><Register /><h6 className="changeform" onClick={ changeForms }>J'ai déjà un compte</h6><hr className="my-4" /><FacebookLogin /><GoogleEmailLogin /></PoopUp> }
            { (typeStatus) && <PoopUp close={ handleClose }><OrderType submitType={ submitType } /></PoopUp> }
            { (commentStatus) && <PoopUp close={ handleClose }><CommentCommande submitComment={ submitComment } /></PoopUp> }
            { (timingStatus) && <PoopUp close={ handleClose }><PickTime submitTiming={ submitTiming } /></PoopUp> }

            { (mapStatus) && <PoopUp close={ handleClose } large><GoogleMap submitMap={ submitMap } /></PoopUp> }
            { (addressStatus) && <PoopUp close={ handleClose }><AddAddress submitAddress={ submitAddress } /></PoopUp> }

            <div className="wrap">

                <SignStatus handleLogin={ handleLogin } />
                <div className="cart-data">
                    {
                        (isDesktop) &&
                        <div className="cart-header">
                            <div className="cart-description">
                                <h4>Ma commande</h4>
                                <p>Parcourez notre menu et ajoutez des éléments a votre commande</p>
                            </div>
                            <div className="cart-counter">
                                <img src={ EcommerceBag } alt="Ecommerce bag" />
                                <span>{ (cart && cart.articles) ? cart.articles.length : 0 }</span>
                            </div>
                        </div>
                    }
                    <div className="cart-body">
                        <div className="elements-row">
                            {
                                (cart && cart.articles &&  cart.articles.length > 0 ) ?
                                    cart.articles.map((single, index) => 
                                        <div className="element-row" key={index}>
                                            {
                                                (isDesktop) &&
                                                    <div className="update-qty">
                                                        <span onClick={ () => handleUpdateCart(index, single['id'], 'increase') }>+</span>
                                                        <span onClick={ () => handleUpdateCart(index, single['id'], 'decrease') }>-</span>
                                                    </div>
                                            }

                                            { (isDesktop) && <div className="qty">x{single['qty']}</div> }
                                            
                                            <div className="title">
                                                <span>{single['title']}</span>
                                                {
                                                    (single['type'] === "compound" && single['details'].length > 0) && 
                                                        single['details'].map((items, key) =>
                                                            (Array.isArray(items)) ? 
                                                                items.map((item, key1) => <i key={key1}>{item}</i>)
                                                            : 
                                                                <i key={key}>{items}</i>
                                                        )
                                                }
                                            </div>
                                            <div className="price">
                                            {single['totalPrice']} Mad
                                                {
                                                    (!isDesktop) && 
                                                        <div className="mobile-update-qty">
                                                            <span onClick={ () => handleUpdateCart(index, single['id'], 'increase') }>+</span>
                                                            <i>{single['qty']}</i>
                                                            <span onClick={ () => handleUpdateCart(index, single['id'], 'decrease') }>-</span>
                                                        </div> 
                                                }
                                            </div>
                                        </div>
                                    )
                                : 
                                    <h6 className="mb-3 text-center">Votre panier est vide</h6>
                            }
                        </div>

                        { 
                            (cart && cart.articles &&  cart.articles.length > 0 ) && 
                            <Fragment>
                                <div className="cart-comment"><span onClick={ handleOrderComment }>{ (comment) ? comment : 'Commenter' }</span></div>
                                <ApplyCoupon title="Commander" />
                                <ApplyOrderType handleOrderType={ handleOrderType } />
                                { (type === 'delivery') && <ApplyAddress handleOrderAddress={handleOrderAddress} handleOrderMap={ handleOrderMap } /> }
                                { (type === 'emporter') && <ApplyTiming handleOrderTiming={ handleOrderTiming } /> }
                                <div className="cart-delivery">
                                    { (type === 'delivery') ? (<div>livraison<span>{ (cart.shipment === 0) ? 'Gratuite' : cart.shipment+' Mad' }</span></div>) : null }
                                    <div className="total">Sous-total<span>{ (cart && cart.subtotal) ? cart.subtotal : "0,00" } Mad</span></div>
                                    { (cart && cart.coupon) ? <div className="total">Remise<span> { (cart.coupon) && cart.coupon + ' Mad' }</span></div> : null }
                                    <div className="total">Total<span>{ (cart && cart.total) ? cart.total : "0,00" } Mad</span></div>
                                </div>
                                <Payment />
                                <div className="cart-footer">
                                    { (status) ? <button onClick={ submitCommand }>Commander</button> : <div className="alert alert-danger">Le restaurant est fermé on pourrait pas recevoir votre commande en ce moment</div> }
                                </div>
                            </Fragment>
                        }

                    </div>
                </div>
                { (loadingForm) && <div className="cartLoading"><Loading /></div> }
            </div>
        </div>
    )
}

export default CartData
