import React, { useContext } from 'react'
import { CartContext } from './../context/CartContext'

const ApplyAddress = ({ handleOrderMap, handleOrderAddress }) => {
    const { address } = useContext(CartContext)

    if(address){
        return (
            <div className="order-type">
                <div onClick={ handleOrderAddress }>
                    { <p>L'adresse de la commande : 
                        <span className="d-block">
                            { (address && address.street) && <span className="d-block">Street: {address.street}</span> }
                            { (address && address.area) && <span className="d-block">Zone: {address.area}</span> }
                            { (address && address.block) && <span className="d-block">Secteur : {address.block}</span> }
                            { (address && address.code_postal) && <span className="d-block">Code postal : {address.code_postal}</span> }
                        </span>
                    </p> }
                </div>
            </div>
        )
    }

    return (
        <div className="order-type" onClick={ handleOrderMap }><p>Sélectionnez l'adresse</p></div>
    )
}

export default ApplyAddress
