import React, { useContext } from 'react'
import { Redirect, Link } from 'react-router-dom'

import PageCoverImage from './../assets/img/page-cover.jpg'
import { UserContext } from './../context/UserContext'

import Layout from './../includes/Layout'
import Container from './../components/Container'
import PageCover from './../components/PageCover'
import RegisterForm from './../components/RegisterForm'
import FacebookLogin from './../components/FacebookLogin'
import GoogleEmailLogin from './../components/GoogleEmailLogin'

const Register = () => {
    const { auth } = useContext(UserContext)

    if(auth) return <Redirect to='/' />
    return (
        <Layout>
            <PageCover title="Page d'inscription" bg={ PageCoverImage } />
            <Container>
                <div className="row">
                    <div className="col-md-8 col-lg-6 offset-md-2 offset-lg-3">
                        <div className="wrapper-log">
                            <RegisterForm />
                            <h6 className="changeform"><Link to="/login">J'ai déjà un compte</Link></h6>
                            <hr className="my-4" />
                            <FacebookLogin />
                            <GoogleEmailLogin />
                        </div>
                    </div>
                </div>
            </Container>
        </Layout>
    )
}

export default Register