import React from 'react'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

const SingleCardItem = ({ title, description, cover_mobile, cover }) => {
    const isDesktop = useMediaQuery({ query: '(min-device-width: 768px)' }) 

    return (
        <div className="single-card-item" style={{ backgroundImage: 'url('+cover_mobile+')' }}>

            { (isDesktop) && <div className="card-item-image"><img src={ cover } alt={title} /></div> }

            <div className="card-item-content">
                { (!isDesktop) && <h2>Un repas sain, frais et sur-mesure en 3 étapes!</h2> }
                <div className="wrap">
                    <h4>{ title }</h4>
                    <p>{ description }</p>
                    <Link to="/commander">Composer</Link>
                </div>
            </div>
        </div>
    )
}

export default SingleCardItem
