import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser'
import App from './App';
import * as serviceWorker from './serviceWorker';

Sentry.init({dsn: "https://0c966a607bbe495e86b1ed431f42fc1e@o399723.ingest.sentry.io/5257257"})

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
