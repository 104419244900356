import React, { useContext } from 'react'
import { CartContext } from './../context/CartContext'

const ApplyTiming = ({ handleOrderTiming }) => {
    const { timing } = useContext(CartContext)

    if(timing){
        return (
            <div className="order-type">
                <div onClick={ handleOrderTiming }>
                    { <p>Heure de la commande : <span>{timing}</span></p>  }
                </div>
            </div>
        )
    }

    return (
        <div className="order-type" onClick={ handleOrderTiming }><p>Sélectionnez l'heure</p></div>
    )
}

export default ApplyTiming
