import React, { useState, useEffect } from 'react'

import SecondLayout from './../includes/SecondLayout'
import PageCover from './../components/PageCover'
import InstagramGallery from './../components/InstagramGallery'

import Container from './../components/Container'
import useFetch from '../units/useFetch'
import FullPageLoading from './../components/FullPageLoading'
import ImageContent from './../assets/img/concept.png'

const  Page = () => {
    const [fields, setFields] = useState(null)
    const [ asyncFetch, { response } ] = useFetch()

    useEffect(() => {
        asyncFetch({ url: "/concept", type: 'page', method: "GET" })
    }, [])

    useEffect(() => {
        setFields(response)
    }, [response])

    return (
        (fields) ? 
            <SecondLayout v2 url='/'>
                <PageCover title={ fields['title'] } bg={ fields['image'] } />                
                <Container>
                    <img src={ImageContent} alt='Content' className="my-5 mx-auto" />
                    {/*<div className="simple-page my-md-5 py-5" dangerouslySetInnerHTML={{ __html: fields['content'] }} />*/}
                </Container>
            </SecondLayout>
        : 
            <FullPageLoading />
    )
}

export default Page
