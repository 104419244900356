import React, { useContext } from 'react'
import { Redirect, Link } from 'react-router-dom';

import Layout from './../includes/Layout'
import Cookies from 'js-cookie'
import PageCoverImage from './../assets/img/page-cover.jpg'

import Container from './../components/Container'
import PageCover from './../components/PageCover'
import LoginForm from './../components/LoginForm'
import FullPageLoading from './../components/FullPageLoading'
import FacebookLogin from './../components/FacebookLogin'
import GoogleEmailLogin from './../components/GoogleEmailLogin'

import { UserContext } from './../context/UserContext'

const Login = ({ history }) => {
    const { auth, dataLoading } = useContext(UserContext)

    if(Cookies.get('166-token')){
        if(dataLoading) return <FullPageLoading />
        if(auth) return <Redirect to='/' />
    }

    return (
        <Layout>
            <PageCover title="Page de connexion" bg={ PageCoverImage } />
        
            <Container>
                <div className="row">
                    <div className="col-md-8 col-lg-6 offset-md-2 offset-lg-3">
                        <div className="wrapper-log">
                            <LoginForm />
                            <h6 className="changeform mt-4"><Link to="/register">Je n'ai pas de compte</Link></h6>
                            <hr className="my-4" />
                            <FacebookLogin />
                            <GoogleEmailLogin />
                        </div>
                    </div>
                </div>
            </Container>
        </Layout>
    )
}

export default Login