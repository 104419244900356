import React from 'react'

import Container from './../components/Container'
import Logo from './../components/Logo'
import Menu from './../components/Menu'
import SocialMedia from './../components/SocialMedia'

const Footer = () => {
    return (
        <footer className="text-center">
            <Container>
                <Logo />
                <Menu />
                <SocialMedia />
                <p>&copy; 2020 116 - By Intuitive design</p>
            </Container>
        </footer>
    )
}

export default Footer