import React, { useState, useEffect, useContext } from 'react'
import { useParams } from "react-router-dom"
import { useMediaQuery } from 'react-responsive'

import SecondLayout from './../includes/SecondLayout'
import LoadingCircle from './../components/LoadingCircle'

import CartData from './../components/CartData'
import Container from './../components/Container'
import HorizontalArticle from './../components/HorizontalArticle'

import CollapseAttribute from './../components/CollapseAttribute'
import useFetch from './../units/useFetch'
import FullPageLoading from './../components/FullPageLoading'

import { CartContext } from './../context/CartContext'

const Composez = () => {
    const isDesktop = useMediaQuery({ query: '(min-device-width: 768px)' }) 
    const { handleAddToCart, setLoadingForm } = useContext(CartContext)
    const { slug } = useParams();

    const [totalCalcule, setTotalCalcule] = useState(0)
    const [article, setArticle] = useState([])
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(true)
    const [seletedDetails, setSeletedDetails] = useState([])
    const [asyncFetch, { response, dataLoading, fetchType }] = useFetch()

    useEffect(() => {
        asyncFetch({ url: `/article/${slug}`, type: 'article', 'method': 'GET' })
    }, [])

    useEffect(() => {
        switch(fetchType){
            case 'article' :
                setArticle(response)
                setTotalCalcule(response.price)
                setLoading(false)
                break
            case 'calculateArticle' :
                setTotalCalcule(response)
                break
            default:
                console.log('initial article compound')
        }
    }, [response])

    const addDetails = (id, attr) => {
        if(error) setError(false)

        let data = seletedDetails
        if(!data[attr]) data[attr] = []
        data[attr].push(id)
        asyncFetch({ url: '/calculate-article', type: 'calculateArticle', method: 'POST', body: JSON.stringify({options: data, article: article.id }) })

        setSeletedDetails(data)
    }
    
    const removeDetails = (id, attr) => {
        if(error) setError(false)
        let data = seletedDetails

        data[attr] = seletedDetails[attr].filter((row, key) => {
            return key !== data[attr].indexOf(id)
        })

        asyncFetch({ url: '/calculate-article', type: 'calculateArticle', method: 'POST', body: JSON.stringify({options: data, article: article.id }) })

        setSeletedDetails(data)
    }
    
    const handleSubmit = () => {
        if(article.details && (article.details.length > 0)){
            setLoadingForm(true)
            let done = true
            article.details.forEach(element => {
                let attr_id = element.attribute.id
                let required_selection = element.attribute.required_selection
                if(required_selection){
                    let data = seletedDetails.filter((row, key) => attr_id === key)
                    if(data.length < 1) done = false
                }
            })

            if(done){
                handleAddToCart(article.id, 'compound', seletedDetails)
                setSeletedDetails([])
                setTotalCalcule(article.price)
            }else{
                setError(true)
            }
        }
    }

    return (
        (!loading) ? 
            <SecondLayout showCart v2={true} url='/commander'>
                <Container>
                    <div className="order-page">
                        <div className="row my-5">
                            <div className="article-composez col-md-8 pr-md-5">
                                <HorizontalArticle clickable={false} addToCart={true} isCompound={true} {...article} />
                                {
                                    (article.details) ? 
                                        <div className="form-composer mt-md-4 pt-md-4">
                                            {
                                                article.details.map((line, index) => 
                                                    <CollapseAttribute 
                                                        key={line.attribute.id} 
                                                        addDetails={addDetails} 
                                                        removeDetails={removeDetails} 
                                                        items={line.options} 
                                                        id={line.attribute.id} 
                                                        title={line.attribute.name} 
                                                        is_unlimited={line.is_unlimited} 
                                                        max={line.maximum} 
                                                        attribute={line.attribute.id} 
                                                        activateQuantity={line.attribute.activate_quantity}
                                                    /> 
                                                )
                                            }
                                        </div>
                                    :
                                        <h2>No details</h2> 
                                }

                                <div className="d-flex align-items-center bar-control">
                                    <div onClick={ handleSubmit } className="btn btn-4 px-4 py-3 text-uppercase"><span>Ajouter aux panier</span></div>
                                    <div className="p-3 total text-right">
                                        {(dataLoading && fetchType === 'calculateArticle' && !isDesktop) && (<LoadingCircle />)}
                                        <span>{totalCalcule} DH</span>
                                    </div>
                                </div>
                                { (error) && <p className="text-danger my-3">Article pas encore terminé</p> }
                            </div>

                            { (isDesktop) && <CartData /> }
                            
                        </div>
                    </div>
                </Container>
            </SecondLayout>
        : 
            <FullPageLoading />
    )
}

export default Composez