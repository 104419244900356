import React, { useEffect, useContext } from 'react'
import {CartContext} from './../context/CartContext'

const Payment = () => {
    const {payment, setPayment} = useContext(CartContext)

    useEffect(() => {
        if(payment !== 'surplace') setPayment('surplace')
    }, [])

    return (
        <div className="payment">
            <ul>
                {/*<li onClick={() => setPayment('card')} className={(payment === 'card') ? 'active' : null}> Payment par cart boncaire</li>*/}
                <li onClick={() => setPayment('surplace')} className={(payment === 'surplace') ? 'active' : null}> Payment surplace</li>
            </ul>
        </div>
    )
}

export default Payment
