import React from 'react'
import { Link } from 'react-router-dom'

import ArrowDown from './../../assets/img/arrow-down.svg'

const SlideGoOrderPage = () => {
    return (
        <div className="slide-go-order-page">
            <Link to='/commander'>Commander en ligne</Link>
            <img src={ ArrowDown } alt="Arrow Down" />
        </div>
    )
}

export default SlideGoOrderPage
