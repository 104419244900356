import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'

import Slider from "react-slick"
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { MainViewSlideshow } from './../units/Units'

import Logo from './Logo'
import Container from './Container'
import Button from './Button'
import SlideGoOrderPage from './Mobile/SlideGoOrderPage'

import useFetch from './../units/useFetch';

const Slideshow = ({loadingSlide}) => {
    const isDesktop = useMediaQuery({ query: '(min-device-width: 768px)' }) 
    const [slideshow, setSlideshow] = useState([])
    const [asyncFetch, { response, fetchType }] = useFetch();

    useEffect(() => {
        asyncFetch({ url: "/slideshow", type: "slideshow", method: "GET" });
    }, []);

    useEffect(() => {
        switch (fetchType) {
            case "slideshow":
                setSlideshow(response);
                loadingSlide(true)
            break;
            default:
                console.log("Slideshow initial call");
        }
    }, [response]);


    if(slideshow.length < 1) return null
    return (
        (slideshow.length > 0) &&
            <div className="homepage-slideshow">
                <Slider className="main-slideshow" {...MainViewSlideshow}>
                    {slideshow.map((slide, index) => 
                        <div className="single-slideshow" key={index}>
                            <div className="bg-slide">
                                { (isDesktop) ? <img src={slide['image']} alt={slide['title']} /> : <img src={slide['mobile_image']} alt={slide['title']} /> }
                                <Container>
                                    <div className="slideshow-content">
                                        { (!isDesktop) && <Logo /> }
                                        { (isDesktop) ? <h2>{slide['title']}</h2> : <h2>{slide['title_mobile']}</h2> }
                                        { ((isDesktop) && slide['button_link'] && slide['button_text']) && <Button link={slide['button_link']} title={slide['button_text']} />}
                                    </div>
                                </Container>
                            </div>
                        </div>
                    )}
                </Slider>
                { (!isDesktop) && <SlideGoOrderPage /> }
            </div>
    )
}

export default Slideshow
