import React, { useState } from 'react'
import {Link} from 'react-router-dom'

import Logo from './../Logo'
import MenuMobile from './MenuMobile'
import ToggleMenu from './ToggleMenu'
import ToggleCart from './../Mobile/ToggleCart'
import GoBack from './../../assets/img/back.svg'

const HeaderMobile = ({ v2, url, showMenu, showCart }) => {
    const [ menu, setMenu ] = useState(false)
    const openMenu = () => {
        setMenu(true)
    }
    const closeMenu = () => {
        setMenu(false)
    }

    return (
        <div className="header-menu px-3 d-flex justify-content-between align-items-center">
            { (v2 && url) && <div className="go-back"><Link to={url}><img src={GoBack} alt="back" /></Link></div> }
            { (v2 && showMenu) && <ToggleMenu openMenu={ openMenu } /> }
            <Logo />
            { (v2 && showCart) && <ToggleCart /> }
            { (v2 && showMenu) && <MenuMobile menu={ menu } closeMenu={ closeMenu } /> }
        </div>
    )
}

export default HeaderMobile
