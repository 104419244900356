import React, { useContext, useState, useEffect, Fragment } from 'react'
import { useHistory } from "react-router-dom";

import AddToCart from './../assets/img/addToCart.svg'
import borderPlus from './../assets/img/border-plus.svg'
import { CartContext } from './../context/CartContext'
import Loading from './../components/Loading'

const HorizontalArticle = ({ clickable=true, isCompound=false, addToCart, selection, id, title, slug, price, description, image }) => {
    const { cart, handleAddToCart } = useContext(CartContext)
    const [state, setState] = useState(false)

    let history = useHistory()

    const addItemToCart = () => {
        setState(true)
        setState(true)
        handleAddToCart(id, 'simple', [])
    }    

    useEffect(() => {
        if(state) setState(false)
    }, [cart])

    const handleSelection = () => {
        return history.push("/composez/" + slug)
    }    

    if(!isCompound){
        return (
            <div className="single-horizontal-article menu-article m-0">
                <div className="article-content">
                    <h4>{ title }</h4>
                    <p>{ description }</p>
                </div>
                <div className="article-price">
                    <span>{ price } Dh</span>
                    <div className="addto-article">
                        { (addToCart) && <img src={borderPlus} onClick={ addItemToCart } alt="" /> }
                    </div>
                </div>
                { (state) && <div className="animation-cart"><Loading /></div> }
            </div>
        )
    }
    
    return (
        <div className="single-horizontal-article mb-4">
            <div className="article-image">
                <div className="article-price">{ price } Dh</div>
                { 
                    (clickable && addToCart) ? 
                        <Fragment>
                            <div className="add-to-cart">
                                <img src={AddToCart} onClick={ addItemToCart } alt="" />
                            </div>
                            { (state) && <div className="animation-cart"><Loading /></div> }
                        </Fragment>
                    : 
                    null 
                }
                { (selection) ? <img src={image} onClick={ () => handleSelection() } alt="" /> : <img src={ image } alt="" /> }
            </div>
            <div className="article-content">
                <h4>{ title }</h4>
                <p>{ description }</p>
            </div>
        </div>
    )
}

export default HorizontalArticle
