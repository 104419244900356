import React, { useEffect, useContext } from 'react'
import { useParams, Redirect } from "react-router-dom"
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { UserContext } from './../context/UserContext'
import Loading from './Loading'

const SignupSchema = Yup.object().shape({
    email: Yup.string().email('Adresse e-mail invalide').required('Champ obligatoire'),
    password: Yup.string().required('Champ obligatoire').min(8, 'Ce champ doit contenir au moins 8 caractères'),
    password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Les mots de passe doivent correspondre')
})

const ResetPasswordForm = ({ token }) => {
    const { ResetPassword, loadingForm, setLoadingForm } = useContext(UserContext)

    return (        
        <Formik
            initialValues={{ email: '', password: '', confirmation_password: '' }}
            validationSchema={SignupSchema}
            onSubmit={(values, { setSubmitting }) => {
                setLoadingForm(true)
                setTimeout(() => {
                    // console.log(token)
                    setSubmitting(false)
                    ResetPassword(values, token)
                }, 400);
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <Form className="form-sign">
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            name="email"
                            id="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                        />
                        <ErrorMessage name="email" component="div" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Nouveau mot de passe</label>
                        <input
                            type="password"
                            className="form-control"
                            name="password"
                            id="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                        />
                        <ErrorMessage name="password" component="div" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password_confirmation">Confirmez le nouveau mot de passe</label>
                        <input
                            type="password"
                            className="form-control"
                            name="password_confirmation"
                            id="password_confirmation"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password_confirmation}
                        />
                        <ErrorMessage name="password_confirmation" component="div" />
                    </div>
                    <div className="form-group mt-4 mb-0 mb-0">
                        <button type="submit" className="btn" disabled={isSubmitting}>Envoyer</button>
                    </div>

                    { (loadingForm) && <div className="mt-3 text-center"><Loading other="mt-4" /></div>  }
                </Form>
            )}
        </Formik>
    )
}

export default ResetPasswordForm